import React, {useEffect, useState} from 'react';
import {Button, Input, message, Modal, Select, Space, Table, Tag, Tooltip} from "antd";
import {DeleteOutlined, EditOutlined, PlusOutlined} from "@ant-design/icons";
import axiosInstance from "../../axios/axiosInstance";
import {MdEditDocument} from "react-icons/md";
import {TbCopyCheckFilled, TbTrashFilled} from "react-icons/tb";
import {BsCapslockFill} from "react-icons/bs";
import {IoHandLeftSharp} from "react-icons/io5";
import {PiBankFill, PiCashRegisterFill} from "react-icons/pi";
import {FaUserTie} from "react-icons/fa6";

function StoreRequest(props) {
   const [pagination, setPagination] = useState({ current: 1, pageSize: 20, total: 0 });
   const [requests, setRequests] = useState([])
   const [selectedRequest, setSelectedRequest] = useState(null);
   const [isModalVisible, setIsModalVisible] = useState(false);
   const [actionType, setActionType] = useState(null); // To distinguish between accept and cancel
   const [description, setDescription] = useState(''); // For storing the description input
   const [isDescriptionModalVisibleForAddComment, setIsDescriptionModalVisibleForAddComment] = useState(false);
   const [isDescriptionModalVisible, setIsDescriptionModalVisible] = useState(false);
   const [fullDescription, setFullDescription] = useState('');
   const [loading, setLoading] = useState(false);

   const openDescriptionModal = (desc) => {
      setFullDescription(desc);
      setIsDescriptionModalVisible(true);
   };
   const openModal = (request) => {
      setSelectedRequest(request);
      setIsModalVisible(true);
   };

   const closeModal = () => {
      setIsModalVisible(false);
      setSelectedRequest(null);
   };

   const getRequests = async (page = 1, pageSize = 20) => {
      setLoading(true);
      try {
         const response = await axiosInstance.get(`/financiers/application/paginate`, {
            params: {
               page,
               per_page: pageSize,
            },
         });
         const { data, meta } = response.data;
         setRequests(data);
         setPagination({
            current: meta.current_page,
            pageSize: meta.per_page,
            total: meta.total,
         });
      } catch (error) {
         console.error("Error fetching requests:", error);
         message.error("Failed to fetch requests.");
      } finally {
         setLoading(false);
      }
   };

   useEffect(() => {
      getRequests();
   }, []);

   const handleTableChange = (pagination) => {
      getRequests(pagination.current, pagination.pageSize);
   };

   const renderStatusTag = (status) => {
      if (!status) {
         return <Tag  className="px-3 py-1 h-8  flex gap-2 max-w-max items-center" color="#bdbdbd"> <img
             className="w-6 h-6"
             src='/check.svg'
         /> Ноаниқ</Tag>;
      }
      switch (status) {
         case 'process':
            return <Tag  className="px-3 py-1 h-8  flex gap-2 max-w-max items-center" color="#3C72F9"> <img
                className="w-6 h-6"
                src='/check.svg'
            />Жараёнда</Tag>;
         case 'accept':
            return <Tag className="px-3 py-1 h-8  flex gap-2 max-w-max items-center" color="#4AC000"> <img
                className="w-6 h-6"
                src='/check.svg'
            />Қабул қилинди</Tag>;
         case 'cancel':
            return <Tag className="px-3 py-1 h-8  flex gap-2 max-w-max items-center" color="#ff3a3a"> <img
                className="w-6 h-6"
                src='/noncheck.svg'
            />Рад этилди</Tag>;
         default:
            return <Tag className="px-3 py-1 h-8  flex gap-2 max-w-max items-center" color="#bdbdbd"> <img
                className="w-6 h-6"
                src='/check.svg'
            />Ноаниқ</Tag>;
      }
   };

   // Handle action button clicks (accept, cancel)
   const handleActionClick = (applicationId, warehouseId, type) => {
      setSelectedRequest({applicationId, warehouseId});
      setActionType(type);
      setIsDescriptionModalVisibleForAddComment(true); // Open the description modal
   };

   // Handle the final action with description validation
   const handleFinalAction = async () => {
      if (description.length < 10) {
         message.error("Изох камида 10 та белгидан иборат бўлиши керак!");
         return;
      }

      const payload = {
         warehouse_id: selectedRequest.warehouseId,
         application_id: selectedRequest.applicationId,
         status: actionType,
         comment: description
      };

      try {
         const response = await axiosInstance.post('/financiers/check/application', payload);
         if (response.data.status) {
            message.success(response.data.message);
            setIsDescriptionModalVisibleForAddComment(false);
            setDescription(''); // Clear the description input
         } else {
            message.error(response.data.message);
         }
      } catch (error) {
         console.error(`Error setting request to ${actionType}:`, error);
         message.error(`Error setting request to ${actionType}`);
      }

      getRequests(); // Refresh the table after action
   };

   const columns = [
      {
         title: 'ID',
         dataIndex: 'id',
         key: 'id',
      },
      {
         title: 'Миқдор',
         dataIndex: 'qty',
         key: 'qty',
      },
      {
         title: 'Сана',
         dataIndex: 'created_at',
         key: 'created_at',
      },
      {
         title: 'Юборувчи',
         dataIndex: 'sender',
         key: 'sender',
         render: (sender) => (
             <Button style={{color:'#00AA81'}} type="link" onClick={() => openModal(sender)}>
                {sender.name}
             </Button>
         )
      },
      {
         title: 'Склад Маҳсулот',
         dataIndex: 'warehouse',
         key: 'warehouse',
         render: (warehouse) => (
             <Button type="link" onClick={() => openModal(warehouse)}>
                {warehouse.name}
             </Button>
         )
      },
      {
         title: 'Тасдиқловчи',
         dataIndex: 'receive',
         key: 'receive',
         render: (receive) => (
             receive ? (

                     <span onClick={() => openModal(receive)}
                         class="inline-flex gap-3 cursor-pointer items-center rounded bg-blue-50/80 max-w-max px-2 py-1 text-xs font-medium text-blue-700">
                       <div className="p-1 rounded bg-blue-500/15">
                           <PiCashRegisterFill   size="18" />
                       </div>  {receive.name}
                     </span>
         )
:
   (
       <span
           class="inline-flex gap-3 items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">     <svg aria-hidden="true" class="inline w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
    </svg>Тасдиқланмаган</span>
             )
         )
      },
      {
         title: 'Холати',
         dataIndex: 'status',
         key: 'status',
         render: (status) => renderStatusTag(status)
      },
      {
         title: 'Изоҳлар', // New column for comments
         dataIndex: 'warehouse',
         key: 'comments',
         render: (warehouse, record) => {
            // Assuming that the comment is within `warehouse.histories`
            const history = warehouse.histories.find((hist) => hist.id === record.id);
            const comment = history?.history?.comment;

            return comment ? (
                comment.length > 10 ? (
                    <Button style={{color: '#00AA81'}} type="link" onClick={() => openDescriptionModal(comment)}>
                       {comment.substring(0, 10)}...
                    </Button>
                ) : (
                    <span>{comment}</span>
                )
            ) : (
                <span>Изох йўқ</span>
            );
         },
      },
       {
       title: 'Ҳаракатлар',
        key: 'actions',
        render: (_, record) => (
        record.status !== 'accept' && record.status !== 'cancel' ? (
            <div>

                <Space size="small">
                    <Tooltip color="#59b55d" title="Қабул қилиш">
                        <button onClick={() => handleActionClick(record.id, record.warehouse.id, 'accept')}
                                disabled={record.status === 'accept' || record.status === 'cancel'}
                                className="group flex h-8 items-center justify-center rounded-md border border-green-600 bg-gradient-to-b from-green-400 via-green-500 to-green-600 px-1.5 text-neutral-50 shadow-[inset_0_1px_0px_0px_#34d399] active:[box-shadow:none]"
                        >
    <span className="flex items-center gap-2 text-white text-[13px] group-active:[transform:translate3d(0,1px,0)]">
      <BsCapslockFill size="18" style={{color: '#fff'}}/>
    </span>
                        </button>
                    </Tooltip>
                    <Tooltip color="#f23e39" title="Рад этиш">
                        <button onClick={() => handleActionClick(record.id, record.warehouse.id, 'cancel')}
                                disabled={record.status === 'accept' || record.status === 'cancel'}
                                className="group flex h-8 items-center justify-center rounded-md border border-red-600 bg-gradient-to-b from-red-400 via-red-500 to-red-600 px-1.5 text-neutral-50 shadow-[inset_0_1px_0px_0px_#f87171] active:[box-shadow:none]"
                        >
    <span className="flex items-center gap-2 text-white text-[13px] group-active:[transform:translate3d(0,1px,0)]">
      <IoHandLeftSharp size="18" style={{color: '#fff'}}/>
    </span>
                        </button>
                    </Tooltip>
                </Space>
            </div>
        ) : null // Не отображаем кнопки
        )
       }
   ];

    return (
        <div>
            <Table rowClassName="even:bg-gray-50/80" size="small" columns={columns} dataSource={requests} rowKey="id"
                   pagination={{
                       current: pagination.current,
                       pageSize: pagination.pageSize,
                       total: pagination.total,
                       showSizeChanger: true,
                   }}
                   onChange={handleTableChange}/>

            {/* Modal for showing details */}
            <Modal
                title="Details"
                open={isModalVisible}
                onCancel={closeModal}
                footer={null}
            >
                {selectedRequest && (
                    <div>
                        <p><strong>Номи:</strong> {selectedRequest?.name}</p>
                        {
                            selectedRequest?.phone && (<p><strong>Рақами:</strong> {selectedRequest?.phone}</p>)
                        }
                        {selectedRequest?.category && (
                            <>
                                <p><strong>Категория:</strong> {selectedRequest?.category.name}</p>
                                {/*<p><strong>Миқдор:</strong> {selectedRequest?.qty}</p>*/}
                                {/*<p><strong>Нархи:</strong> {selectedRequest?.price}</p>*/}
                            </>
                        )}
                    </div>
                )}
            </Modal>

            {/* Modal for entering description */}
            <Modal
                title={`Изох ${actionType === 'accept' ? 'қабул қилиш' : 'рад этиш'} учун`}
                open={isDescriptionModalVisibleForAddComment}
                onCancel={() => setIsDescriptionModalVisibleForAddComment(false)}
                onOk={handleFinalAction}
              okText={actionType === 'accept' ? 'Қабул қилиш' : 'Рад этиш'}
          >
             <Input.TextArea
                 placeholder="Изох киритинг..."
                 value={description}
                 onChange={(e) => setDescription(e.target.value)}
                 rows={4}
             />
          </Modal>

          <Modal
              title="Тўлиқ Изох"
              open={isDescriptionModalVisible}
              onCancel={() => setIsDescriptionModalVisible(false)}
              footer={null}
          >
             <p>{fullDescription}</p>
          </Modal>
       </div>
   );
}

export default StoreRequest;