import React, {useEffect, useState} from 'react';
import {Button, Input, message, Modal, Space, Table, Tooltip} from "antd";
import {DeleteOutlined, EditOutlined, PlusOutlined} from "@ant-design/icons";
import axiosInstance from "../../axios/axiosInstance";
import {MdEditDocument} from "react-icons/md";
import {TbTrashFilled} from "react-icons/tb";

function StoreCategory(props) {

   const [categories, setCategories] = useState([]);
   const [isOpen, setIsOpen] = useState(false);
   const [modalMode, setModalMode] = useState('add'); // 'add' or 'edit'
   const [selectedCategory, setSelectedCategory] = useState(null);
   const [categoryName, setCategoryName] = useState('');


   const fetchCategories = async () => {
      try {
         const response = await axiosInstance.get('/financiers/categories');
         setCategories(response.data.data);
      } catch (error) {
         console.error('Error fetching categories:', error);
      }
   };

   useEffect(() => {
      fetchCategories();
   }, []);

   const openModal = (mode, category = null) => {
      setModalMode(mode);
      if (mode === 'edit' && category) {
         setSelectedCategory(category);
         setCategoryName(category.name);
      } else {
         setCategoryName('')
      }
      setIsOpen(true);
   };

   const closeModal = () => {
      setIsOpen(false);
      setSelectedCategory(null);
      setCategoryName('')
   };

   const deleteCategory = async (categoryId) => {
      try {
         await axiosInstance.delete(`/financiers/categories/${categoryId}`);
         message.success('category deleted successfully');
      } catch (error) {
         console.error('Error deleting category:', error);
         message.error('Error deleting category');
      }
      fetchCategories();
   };

   const addOrUpdateCategory = async () => {
      try {
         if (modalMode === 'add') {
            const response = await axiosInstance.post('/financiers/categories', {name: categoryName});
            setCategories([...categories, response.data]);
            message.success('category added successfully');
         } else if (modalMode === 'edit' && selectedCategory) {
            await axiosInstance.put(`/financiers/categories/${selectedCategory.id}`, {name: categoryName});
            message.success('Category updated successfully');
         }
         closeModal();
         fetchCategories();
      } catch (error) {
         console.error('Error adding/updating category:', error);
         message.error('Error adding/updating category');
      }
   };

   const columns = [
      {
         title: 'ID',
         dataIndex: 'id',
         key: 'id',
      },
      {
         title: 'Номи',
         dataIndex: 'name',
         key: 'name',
      },
      {
         title: 'Харакат',
         key: 'action',
         render: (text, record) => (
             <Space size="small">
                <Tooltip color="#59b55d" title="Ўзгартириш">
                   <button  onClick={() => openModal('edit', record)}
                            className="group flex h-8 items-center justify-center rounded-md border border-green-600 bg-gradient-to-b from-green-400 via-green-500 to-green-600 px-1.5 text-neutral-50 shadow-[inset_0_1px_0px_0px_#34d399] active:[box-shadow:none]"
                   >
    <span className="block group-active:[transform:translate3d(0,1px,0)]">
      <MdEditDocument size="20" style={{color: '#fff'}}/>
    </span>
                   </button>
                </Tooltip>
                <Tooltip color="#f23e39" title="Удалить">
                   <button onClick={() => deleteCategory(record.id)}
                           className="group flex h-8 items-center justify-center rounded-md border border-red-600 bg-gradient-to-b from-red-400 via-red-500 to-red-600 px-1.5 text-neutral-50 shadow-[inset_0_1px_0px_0px_#f87171] active:[box-shadow:none]"
                   >
    <span className="block group-active:[transform:translate3d(0,1px,0)]">
      <TbTrashFilled  size="20" style={{color: '#fff'}}/>
    </span>
                   </button>
                </Tooltip>
             </Space>
         ),
      },
   ];

   return (
       <div>
          <div className="flex px-10 justify-between items-center mb-4">
             <h1 className="text-xl font-semibold mb-3">Барча категориялар</h1>
             <Button type="primary" icon={<PlusOutlined/>} onClick={() => openModal('add')}>
                Янги қўшиш
             </Button>
          </div>
          <Table columns={columns} dataSource={categories} rowKey="id" pagination={{pageSize: 10}}/>

          <Modal
              title={modalMode === 'add' ? 'категория қўшиш' : 'категория ўргатириш'}
              open={isOpen}
              onCancel={closeModal}
              onOk={addOrUpdateCategory}
              okText={modalMode === 'add' ? 'Қўшиш' : 'Янгилаш'}
          >
             <Input
                 placeholder="номи"
                 value={categoryName}
                 onChange={(e) => setCategoryName(e.target.value)}
                 style={{marginBottom: 16}}
             />
          </Modal>
       </div>
   );
}

export default StoreCategory;