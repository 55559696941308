import React, { Fragment, useEffect, useState } from 'react';
import { useCountries } from "use-react-countries";
import {Button, Input, Modal, Select, Table, Typography, Row, Col, Space, Spin, Form, Tooltip} from 'antd';
import { UserAddOutlined, SearchOutlined, EditOutlined, DeleteOutlined, PhoneOutlined } from '@ant-design/icons';
import axiosInstance from "../../axios/axiosInstance";
import {MdEditDocument} from "react-icons/md";
import {TbTrashFilled} from "react-icons/tb";

const TABLE_HEAD = ["ФИО", "Логин", "Телефон", "Роль", "Харакат"];

export default function Users() {
    const { countries } = useCountries();
    const [country, setCountry] = useState(177);
    const { name, flags, countryCallingCode } = countries[country];
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [editUser, setEditUser] = useState(null);
    const [userData, setUserData] = useState({
        name: "",
        login: "",
        password: "",
        confirmPassword: "",
        roles: [],
        phone: ""
    });

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        setIsLoading(true);
        try {
            const response = await axiosInstance.get("/admin/users");
            setUsers(response.data.data);
        } catch (error) {
            console.error("Error fetching users:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const createUser = async (userData) => {
        setIsLoading(true);
        try {
            const newUser = { ...userData, roles: userData.roles.length > 0 ? userData.roles[0].label : '' };
            const response = await axiosInstance.post('/admin/users', newUser);
            setUsers([...users, response.data.data]);
            closeModal();
        } catch (error) {
            console.error('Error creating user:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const updateUser = async (userId, updatedUserData) => {
        setIsLoading(true);
        try {
            const updatedUser = { ...updatedUserData, roles: updatedUserData.roles.length > 0 ? updatedUserData.roles[0].label : '' };
            const response = await axiosInstance.put(`/admin/users/${userId}`, updatedUser);
            setUsers(users.map(user => (user.id === userId ? response.data.data : user)));
            closeModal();
        } catch (error) {
            console.error('Error updating user:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const deleteUser = async (userId) => {
        setIsLoading(true);
        try {
            await axiosInstance.delete(`/admin/users/${userId}`);
            setUsers(users.filter(user => user.id !== userId));
        } catch (error) {
            console.error('Error deleting user:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const closeModal = () => {
        setIsOpen(false);
        setEditUser(null);
        setUserData({
            name: "",
            login: "",
            password: "",
            confirmPassword: "",
            roles: [],
            phone: ""
        });
    };

    const openModal = () => {
        setIsOpen(true);
    };

    const openEditDialog = (user) => {
        setEditUser(user);
        setUserData({
            name: user.name,
            login: user.login,
            password: "",
            confirmPassword: "",
            roles: user.roles.map(role => ({ label: role, value: role })),
            phone: user.phone
        });
        setIsOpen(true);
    };

    const roleTranslations = {
        admin: "Администратор",
        doctor: "Врач",
        reception: "Ресепшн",
        financier: "Бухгалтер"
    };


    const columns = [
        { title: "ФИО", dataIndex: "name", key: "name" },
        { title: "Логин", dataIndex: "login", key: "login" },
        { title: "Телефон", dataIndex: "phone", key: "phone" },
        { title: "Роль", dataIndex: "roles", key: "roles",   render: (roles) => {
                return roles.map(role => roleTranslations[role] || role).join(", ");
            } },
    //     {
    //         title: "Харакат", key: "action", render: (text, record) => (
    //
    //             <Space size="small">
    //                 <Tooltip color="#59b55d" title="Врачни ўзгартириш">
    //                     <button  onClick={() => openEditDialog(record)}
    //                             className="group flex h-8 items-center justify-center rounded-md border border-green-600 bg-gradient-to-b from-green-400 via-green-500 to-green-600 px-1.5 text-neutral-50 shadow-[inset_0_1px_0px_0px_#34d399] active:[box-shadow:none]"
    //                     >
    // <span className="block group-active:[transform:translate3d(0,1px,0)]">
    //   <MdEditDocument size="20" style={{color: '#fff'}}/>
    // </span>
    //                     </button>
    //                 </Tooltip>
    //                 <Tooltip color="#f23e39" title="Удалить">
    //                     <button onClick={() => deleteUser(record.id)}
    //                             className="group flex h-8 items-center justify-center rounded-md border border-red-600 bg-gradient-to-b from-red-400 via-red-500 to-red-600 px-1.5 text-neutral-50 shadow-[inset_0_1px_0px_0px_#f87171] active:[box-shadow:none]"
    //                     >
    // <span className="block group-active:[transform:translate3d(0,1px,0)]">
    //   <TbTrashFilled  size="20" style={{color: '#fff'}}/>
    // </span>
    //                     </button>
    //                 </Tooltip>
    //             </Space>
    //         )
    //     }
    ];

    return (
        <div >
            <div className="px-10" >
                <Typography.Title level={3}>Фойдаланувчилар</Typography.Title>
                <Row justify="space-between" gutter={16}>
                    <Col>
                        <Input.Search
                            placeholder="Қидириш"
                            enterButton={<SearchOutlined />}
                            onSearch={(value) => console.log(value)}
                            style={{ width: 300 }}
                        />
                    </Col>
                    <Col>
                        <Button type="primary" icon={<UserAddOutlined />} onClick={openModal}>Янги қўшиш</Button>
                    </Col>
                </Row>
            </div>

            <Table
                columns={columns}
                dataSource={users}
                loading={isLoading}
                rowKey="id"
                className="mt-4"
            />


            <Modal
                title={editUser ? "Фойдаланувчини таҳрирлаш" : "Янги фойдаланувчи"}
                visible={isOpen}
                onCancel={closeModal}
                centered
                footer={null}
            >
                <Form
                    layout="vertical"
                    onFinish={editUser ? () => updateUser(editUser.id, userData) : () => createUser(userData)}
                    initialValues={userData}
                >
                    <Form.Item
                        label="ФИО: *"
                        name="name"
                        rules={[{ required: true, message: 'ФИОни киритинг!' }]}
                    >
                        <Input
                            value={userData.name}
                            onChange={(e) => setUserData({ ...userData, name: e.target.value })}
                            placeholder="ФИО киритинг"
                        />
                    </Form.Item>

                    <Form.Item
                        label="Логин"
                        name="login"
                    >
                        <Input
                            value={userData.login}
                            onChange={(e) => setUserData({ ...userData, login: e.target.value })}
                            placeholder="Логин киритинг"
                        />
                    </Form.Item>


                    <Form.Item
                        label="Пароль: *"
                        name="password"
                        rules={[{ required: true, message: 'Парольни киритинг!' }]}
                    >
                        <Input.Password
                            value={userData.password}
                            onChange={(e) => setUserData({ ...userData, password: e.target.value })}
                            placeholder="Пароль киритинг"
                        />
                    </Form.Item>

                    <Form.Item
                        label="Паролни такрорланг"
                        name="confirmPassword"
                        rules={[{ required: true, message: 'Паролни такрор киритинг!' }]}
                    >
                        <Input.Password
                            value={userData.confirmPassword}
                            onChange={(e) => setUserData({ ...userData, confirmPassword: e.target.value })}
                            placeholder="Паролни такрор киритинг"
                        />
                    </Form.Item>

                    <Form.Item
                        label="Роллар"
                        name="roles"
                    >
                        <Select
                            placeholder="Ролларни танланг"
                            value={userData.roles}
                            onChange={(value) => setUserData({ ...userData, roles: value })}
                            style={{ width: "100%" }}
                        >
                            <Select.Option value="admin">Администратор</Select.Option>
                            <Select.Option value="doctor">Врач</Select.Option>
                            <Select.Option value="reception">Ресепшн</Select.Option>
                            <Select.Option value="financier">Бухгалтер</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="Телефон"
                        name="phone"
                    >
                        <Input
                            value={userData.phone}
                            onChange={(e) => setUserData({ ...userData, phone: e.target.value })}
                            placeholder="Телефон рақамини киритинг"
                            prefix={<PhoneOutlined />}
                        />
                    </Form.Item>

                    <div style={{ textAlign: 'right' }}>
                        <Button onClick={closeModal} style={{ marginRight: 8 }}>
                            Йопиш
                        </Button>
                        <Button type="primary" htmlType="submit">
                            {editUser ? "Таҳрирлаш" : "Қўшиш"}
                        </Button>
                    </div>
                </Form>
            </Modal>
        </div>
    );
}
