import React, { useState } from "react";
import {Form, Input, DatePicker, Radio, Button, Steps, Table, Drawer, AutoComplete} from "antd";
import TextArea from "antd/es/input/TextArea";
import axiosInstance from "../../axios/axiosInstance";
import toast from "react-hot-toast";
import qs from "qs";
import {FaFileWord, FaRightLong} from "react-icons/fa6";
import {FaAngleDoubleRight, FaHandPointRight} from "react-icons/fa";
import {BsFillArrowRightSquareFill} from "react-icons/bs";
const { Step } = Steps;

const Form43 = (visitId) => {
    const [current, setCurrent] = useState(0);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [visible, setVisible] = useState(false);
    const teethNumbers = [8, 7, 6, 5, 4, 3, 2, 1, 1, 2, 3, 4, 5, 6, 7, 8];
    const inputStyle = "w-12 h-10 text-center";
    const [form] = Form.useForm();
    const [step1Response, setStep1Response] = useState(null);
    const [teethData, setTeethData] = useState({});

    const handleTeethChange = (position, value) => {
        // Update the teeth data with the correct format for each tooth
        setTeethData((prevData) => ({
            ...prevData,
            [`teeth[${position}]`]: value, // This will store data like 'teeth[a1]': 'G'
        }));
    };


    const options = [
        { value: "О" },
        { value: "R" },
        { value: "C" },
        { value: "P" },
        { value: "PT" },
        { value: "A" },
        { value: "I" },
        { value: "II" },
        { value: "III" },
        { value: "K" },
        { value: "И" },
    ]; // З

    const handleSubmitStep1 = async (values) => {
        const formData = new FormData();
        formData.append("visit_id", visitId.visitId);
        formData.append("complaint", values.complaint || "");
        formData.append("diagnoz", values.diagnosis || "");
        formData.append("illness", values.illness || "");
        formData.append("ill_history", values.ill_history || "");

        try {
            const response = await axiosInstance.post("/doctor/form/step1", formData);
            setStep1Response(response.data.data);
            toast.success("1-qadam muvaffaqiyatli saqlandi!");
            next(); // Proceed to the next step on success
        } catch (error) {
            console.error("Error submitting Step 1:", error);
        }
    };

    const handleSubmitStep2 = async (values) => {
        const formData = new FormData();
        formData.append("anamnez1", values.anamnez1 || "");
        formData.append("anamnez2", values.anamnez2 || "");
        formData.append("anamnez3", values.anamnez3 || "");
        formData.append("anamnez4", values.anamnez4 || "");
        formData.append("anamnez5", values.anamnez5 || "");
        formData.append("anamnez6", values.anamnez6 || "");
        formData.append("anamnez7", values.anamnez7 || "");
        try {
            const response = await axiosInstance.put(`/doctor/form/step2/${step1Response.id}`, formData);
            toast.success("2-qadam muvaffaqiyatli saqlandi!");
            next(); // Proceed to the next step on success
        } catch (error) {
            console.error("Error submitting Step 2:", error);
        }
    };

    const handleSubmitStep4 = async (values) => {
        const formData = new FormData();
        formData.append("treatment", values.treatment || "");
        try {
            const response = await axiosInstance.put(`/doctor/form/step4/${step1Response.id}`, formData);
            toast.success("4-qadam muvaffaqiyatli saqlandi!");
        } catch (error) {
            console.error("Error submitting Step 4:", error);
        }
    };

    const handleExport = async () => {
        try {
            const response = await axiosInstance.get(`/doctor/form/export/${step1Response.id}`);

            if (response.data.success && response.data.data.url) {
                const url = response.data.data.url;
                const link = document.createElement("a");
                link.href = url;
                link.download = "Form43.docx"; // Provide a default filename
                link.click();

                toast.success("СТОМАТОЛОГИЯ БЕМОРИНИНГ ТИББИЙ ВАРАКАСИ МУВАФФАҚИЯТЛИ ЮКЛАБ ОЛИНДИ");
            } else {
                toast.error("Формани юклаб олишда хатолик юз берди.");
            }
        } catch (error) {
            console.error("Error exporting the form:", error);
            toast.error("Юклаб олишда хатолик юз берди.");
        }
    };


    const handleSubmitStep3 = async () => {
        const formData = new FormData();
        const formattedData = qs.stringify(teethData);
        Object.keys(teethData).forEach((key) => {
            formData.append(key, teethData[key]);
        });

        try {
            const response = await axiosInstance.put(`/doctor/form/step3/${step1Response?.id}`, formattedData);
            next();
            toast.success("3-qadam muvaffaqiyatli saqlandi!");
        } catch (error) {
            console.error("Error submitting teeth data:", error);
        }
    };


    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    const onChange = (value) => {
        setCurrent(value);
    };

    const closeDrawer = () => {
        setVisible(false);
    };

    return (
        <div className="max-w-screen-2xl  relative mx-auto p-8 border border-gray-300 rounded-lg bg-white">


            {/* Steps component */}
           <div className="flex relative justify-between gap-10">
               <Steps  onChange={onChange} direction="vertical" current={current} className="mb-6 w-[20%] border-r border-gray-100 sticky top-32">
                   <Step title="I Қисм" />
                   <Step title="II Қисм" />
                   <Step title="II Қисм" />
                   <Step title="IV Қисм" />
               </Steps>

               <div className="w-full">
                   <div className="flex items-center justify-between mb-5">
                       <h1 className="text-lg font-medium uppercase font-roman  ">УЗБЕКИСТОН  РЕСПУБЛИКАСИ <br/> СОГЛИКНИ САКЛАШ ВАЗИРЛИГИ</h1>
                       <img src="/uzb.jpg" className="h-20" />
                       <h1 className="text-lg font-medium uppercase font-roman">Согликни саклаш вазирлигининг <br/> 043/у
                           шаклидаги тиббий хужжати </h1>
                   </div>
                   <h1 className="text-2xl font-bold text-center font-roman mb-6">СТОМАТОЛОГИЯ БЕМОРИНИНГ ТИББИЙ ВАРАКАСИ</h1>
                   {current === 0 && (
                       <Form
                           onFinish={handleSubmitStep1}
                           className="mx-auto"
                           layout="vertical"
                       >
                           <Form.Item
                               label="Диагноз"
                               name="diagnosis"
                               rules={[
                                   { required: true, message: "Илтимос, диагнозни киритинг!" },
                               ]}
                           >
                               <TextArea rows={2} placeholder="Диагноз" />
                           </Form.Item>
                           <Form.Item
                               label="Шикояти"
                               name="complaint"
                               rules={[
                                   { required: true, message: "Илтимос, шикоятни киритинг!" },
                               ]}
                           >
                               <TextArea rows={2} placeholder="Шикояти" />
                           </Form.Item>
                           <Form.Item
                               label="Болаликда ва кейинги пайтда қанақа касал бўлган"
                               name="ill_history"
                               rules={[
                                   {
                                       required: true,
                                       message: "Илтимос, касаллик тарихи ҳақида маълумот киритинг!",
                                   },
                               ]}
                           >
                               <TextArea rows={4} placeholder="Касаллик тарихи" />
                           </Form.Item>
                           <Form.Item
                               label="Ҳозирги касалликни бошланиш тарихи"
                               name="illness"
                               rules={[
                                   {
                                       required: true,
                                       message: "Илтимос, ҳозирги касаллик тарихи ҳақида маълумот киритинг!",
                                   },
                               ]}
                           >
                               <TextArea rows={4} placeholder="Ҳозирги касаллик тарихи" />
                           </Form.Item>
                           <Button
                               type="primary"
                               htmlType="submit"
                               block
                               size="large"
                               className="text-sm text-white"
                           >
                               Сақлаш ва Давом этиш
                           </Button>
                       </Form>
                   )}

                   {current === 1 && (
                       <Form onFinish={handleSubmitStep2} className=" mx-auto" layout="vertical">
                           <Form.Item label="Эпид анамнез: 1) Инфекцион беморлар билан контактда бўлганми?"
                                      name="anamnez1">
                               <TextArea rows={4} placeholder="Инфекцион беморлар билан контактда бўлганми?"/>
                           </Form.Item>
                           <Form.Item label="2) 1 ҳафта – 2 ой мобайнида турар жойидан четга чиққанми?"
                                      name="anamnez2">
                               <TextArea rows={4} placeholder="Турар жойидан четга чиққанми?"/>
                           </Form.Item>
                           <Form.Item label="3) Қайси инфекцион касалликларни бошидан кечирган?"
                                      name="anamnez3">
                               <TextArea rows={4} placeholder="Қайси инфекцион касалликларни бошидан кечирган?"/>
                           </Form.Item>
                           <Form.Item label="4) Стационар ва амбулатор даволанганми?" name="anamnez4">
                               <TextArea rows={4} placeholder="Стационар ва амбулатор даволанганми?"/>
                           </Form.Item>
                           <Form.Item label="5) Парентерал муолажалар олганми?" name="anamnez5">
                               <TextArea rows={4} placeholder="Парентерал муолажалар олганми?"/>
                           </Form.Item>
                           <Form.Item label="6) Маиший хизматлардан фойдаланганми?" name="anamnez6">
                               <TextArea rows={4} placeholder="Маиший хизматлардан фойдаланганми?"/>
                           </Form.Item>
                           <Form.Item label="Аллергоанамнез: Турли дори ва овқат маҳсулотларига аллергия бор?"
                                      name="anamnez7">
                               <TextArea rows={2} placeholder="Аллергия бор, йўқ?"/>
                           </Form.Item>

                           <Button
                               type="primary"
                               htmlType="submit"
                               block
                               size="large"
                               className="text-sm flex items-center justify-center gap-3 text-white"
                           >
                               Сақлаш ва Давом этиш
                               <BsFillArrowRightSquareFill  className="flex items-center" size="20"  />
                           </Button>

                           {/*<Form.Item*/}
                           {/*    label="Анамнез ва клиник кўрсатмаларга кўра аниқлашга мўлжалланган ташхис (диагноз)"*/}
                           {/*    name="diagnosis">*/}
                           {/*    <TextArea rows={2} placeholder="Ташхис"/>*/}
                           {/*</Form.Item>*/}
                       </Form>
                   )}

                   {current === 2 && (
                       <Form onFinish={handleSubmitStep3} className=" mx-auto" layout="vertical">
                           <h3 className="text-xl  font-semibold">Ташки Куриниш</h3>
                           <div className="flex flex-row  space-x-4">
                               {/* Левая текстовая часть */}
                               <div className=" border  border-gray-300 border-r-0 p-4">
                                   <p className="font-bold mb-2">Огиз бушлиги куриниши.</p>
                                   <p>Тишларнинг холати:</p>
                                   <ul className="list-disc list-inside">
                                       <li className="mt-1.5">Тортилган тиш -
                                           <span
                                               className="inline-flex gap-3 items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">О</span>
                                       </li>
                                       <li className="mt-1.5">Томир колган - <span
                                           className="inline-flex gap-3 items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">R</span>
                                       </li>
                                       <li className="mt-1.5">Кариес - <span
                                           className="inline-flex gap-3 items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">C</span>
                                       </li>
                                       <li className="mt-1.5">Пульпит - <span
                                           className="inline-flex gap-3 items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">P</span>,
                                           Периодонтит - <span
                                               className="inline-flex gap-3 items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">PT</span>
                                       </li>
                                       <li className="mt-1.5">Парадонтоз - <span
                                           className="inline-flex gap-3 items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">A</span>
                                       </li>
                                       <li className="mt-1.5">Кимирлаш - <span
                                           className="inline-flex gap-3 items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 font-serif">I-II-III</span>
                                       </li>
                                       <li className="mt-1.5">Коплама - <span
                                           className="inline-flex gap-3 items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">K</span>,
                                           Сунъий Тиш - <span
                                               className="inline-flex gap-3 items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">И</span>
                                       </li>
                                   </ul>
                               </div>

                               {/* Правая часть с таблицей */}
                               <div className="flex flex-col items-center space-y-4 w-2/3">
                                   {/* Top rows */}
                                   <div className="space-y-1">
                                       {Array.from({length: 2}).map((_, rowIndex) => {
                                           const rowLabel = String.fromCharCode(97 + rowIndex); // 'a', 'b', etc.
                                           return (
                                               <div key={`top-row-${rowIndex}`}
                                                    className="flex justify-center space-x-1">
                                                   {teethNumbers.map((_, colIndex) => {
                                                       const colLabel = colIndex + 1; // Column numbers 1-16
                                                       const position = `${rowLabel}${colLabel}`; // For example, a1, b3, etc.

                                                       return (
                                                           <AutoComplete
                                                               popupMatchSelectWidth={false}
                                                               key={`top-${rowIndex}-${colIndex}`}
                                                               options={options}
                                                               className="w-10 h-10 text-center"
                                                               placeholder=""
                                                               onChange={(value) => handleTeethChange(position, value)} // Pass position and value to handler
                                                               filterOption={(inputValue, option) =>
                                                                   option.value.toLowerCase().includes(inputValue.toLowerCase())
                                                               }
                                                           />
                                                       );
                                                   })}
                                               </div>
                                           );
                                       })}
                                   </div>

                                   {/* Number rows */}
                                   <div className="flex justify-center space-x-1 mt-4">
                                       {teethNumbers.map((num, index) => (
                                           <div key={`number-${index}`}
                                                className="w-10 h-10 flex items-center justify-center border border-gray-300 bg-gray-100">
                                               {num}
                                           </div>
                                       ))}
                                   </div>

                                   {/* Bottom rows */}
                                   <div className="space-y-1 mt-4">
                                       {Array.from({length: 3}).map((_, rowIndex) => {
                                           const rowLabel = String.fromCharCode(97 + rowIndex + 2); // Starts from 'c' for bottom rows
                                           return (
                                               <div key={`bottom-row-${rowIndex}`}
                                                    className="flex justify-center space-x-1">
                                                   {teethNumbers.map((_, colIndex) => {
                                                       const colLabel = colIndex + 1; // Column numbers 1-16
                                                       const position = `${rowLabel}${colLabel}`; // For example, c1, d10, etc.

                                                       return (
                                                           <AutoComplete
                                                               popupMatchSelectWidth={false}
                                                               key={`bottom-${rowIndex}-${colIndex}`}
                                                               options={options}
                                                               className="w-10 h-10 text-center"
                                                               placeholder=""
                                                               onChange={(value) => handleTeethChange(position, value)} // Pass position and value to handler
                                                               filterOption={(inputValue, option) =>
                                                                   option.value.toLowerCase().includes(inputValue.toLowerCase())
                                                               }
                                                           />
                                                       );
                                                   })}
                                               </div>
                                           );
                                       })}
                                   </div>
                               </div>
                           </div>

                           <Button block type="primary" size="large" className="text-sm shadow-none" htmlType="submit">Сақлаш ва давом этиш</Button>
                       </Form>
                   )}

                   {current === 3 && (
                       <Form onFinish={handleSubmitStep4} className="max-w-4xl mx-auto" layout="vertical">
                           <Form.Item label="Даволаши" name="treatment">
                               <TextArea rows={10} placeholder="Даволаши"/>
                           </Form.Item>

                           <Button block type="primary" size="large" className="text-sm shadow-none" htmlType="submit">Сақлаш ва тугатиш</Button>
                       </Form>
                   )}

               </div>


           </div>

            <Form className="w-full" layout="vertical">


                {/* Navigation buttons */}
                <div className="flex justify-between mt-6">
                    <Button onClick={prev} disabled={current === 0}>
                        Орқага
                    </Button>

                    <button onClick={handleExport}
                            className="group flex h-10 items-center justify-center rounded-md border border-blue-600 bg-gradient-to-b from-blue-400 via-blue-500 to-blue-600 px-4 text-neutral-50 shadow-[inset_0_1px_0px_0px_#93c5fd] hover:from-blue-600 hover:via-blue-600 hover:to-blue-600 active:[box-shadow:none]">
                        <span class="group-active:[transform:translate3d(0,1px,0)] text-white gap-2 flex items-center">
                            <FaFileWord size="20" />
                            Word справка 43ни юклаб олиш</span></button>
                    {/*<Button type="primary" disabled={current !== 3} onClick={handleExport}>*/}
                    {/*    Word справка 43ни юклаб олиш*/}
                    {/*</Button>*/}
                    {/*<Button type="primary" onClick={next} disabled={current === 3}>*/}
                    {/*    Кейинги*/}
                    {/*</Button>*/}
                </div>
            </Form>
        </div>
    );
};

export default Form43;
