import React, { useEffect, useState } from 'react';
import {Table, Tag, Tooltip} from 'antd';
import axiosInstance from "../axios/axiosInstance";
import {Link, useNavigate} from "react-router-dom";
import {HiDocumentMagnifyingGlass} from "react-icons/hi2";
import {FaHandshakeAngle} from "react-icons/fa6";
import {endVisit} from "../services/visitService";
import { Dialog } from '@headlessui/react';
import toast from "react-hot-toast";
const DoctorVisits = () => {
    const [visits, setVisits] = useState([]);
    const [loading, setLoading] = useState(true);
    const [doctors, setDoctors] = useState([]);
    const [patients, setPatients] = useState([]);
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRecordId, setSelectedRecordId] = useState(null);
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        // Retrieve and parse the user data from localStorage
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            setUserData(JSON.parse(storedUser));
        }
    }, []);

    const openModal = (id) => {
        setSelectedRecordId(id);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedRecordId(null);
    };



    const statusLabels = {
        queue: 'Навбатда...',
        examined: 'Қабулда...',
        new: 'Янги кабул',
        pending: 'Тўлов кутилмоқда',
        payed: 'Тўланган',
        revisit: 'Навбатда...',
        closed: 'Йопилган',
        null: 'Фаол емас',
    };

    const onRowClick = (record) => {
        navigate(`/patient/${record.patient_id}`);
    };


    const statusColors = {
        queue: '#EF9300',  // Muted yellow
        examined: '#4AC000',  // Flat green
        new: '#3C72F9',  // Flat sky blue
        pending: '#FFA866',  // Soft coral orange
        closed: '#ff3a3a',  // Flat red
        payed: '#9A77FF',  // Soft violet
        revisit: '#ffcf22',  // Muted yellow
        null: '#bdbdbd',  // Soft grey
    };

    const iconBackgrounds = {
        queue: 'bg-yellow-50',     // Very light yellow
        examined: 'bg-green-50',   // Very light green
        new: 'bg-blue-50',         // Very light blue
        pending: 'bg-orange-50',   // Very light coral
        closed: 'bg-red-50',       // Very light red
        payed: 'bg-purple-50',     // Very light violet
        revisit: 'bg-yellow-50',   // Very light yellow
        null: 'bg-gray-200',       // Very light grey
    };

    const fetchData = async () => {
        try {
            // Fetch the visits data
            const visitResponse = await axiosInstance.get('/doctor/visit');
            let  visitsData = visitResponse.data.data;

            // Filter visits to only include those with status 'queue' or 'examined'
            visitsData = visitsData.filter(visit => visit.status === 'queue' || visit.status === 'examined');


            // Fetch the doctors data
            const doctorResponse = await axiosInstance.get("/admin/users");
            const doctorUsers = doctorResponse.data.data.filter(user => user.roles.includes('doctor'));
            setDoctors(doctorUsers);

            // Fetch the patients data
            const patientResponse = await axiosInstance.get("/patients");
            const patientsData = patientResponse.data.data;
            setPatients(patientsData);

            // Map visits data to include doctor and patient names
            const mappedVisits = visitsData.map(visit => {
                const doctor = doctorUsers.find(doc => doc.id === visit.doctor_id);
                const patient = patientsData.find(pat => pat.id === visit.patient_id);
                return {
                    ...visit,
                    doctor_name: doctor ? doctor.name : 'Unknown Doctor',
                    patient_name: patient ? patient.name : 'Unknown Patient', // Map patient name
                };
            });

            setVisits(mappedVisits);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        fetchData();
    }, []);

    const confirmEndVisit = () => {
        handleEndVisit(selectedRecordId);
    };

    const handleEndVisit = async (visitId) => {
        try {
            await endVisit(visitId);
            fetchData();
            closeModal();
            toast.success('Қабул муваффақиятли йопилди!')
        } catch (error) {
            console.error('Error ending visit:', error);

        }
    };


    const columns = [
        {
            title: '№',
            key: 'id',
            render: (text, record, index) => index + 1, // Render index + 1 as the ID
        },
        {
            title: 'Бемор Номи',
            dataIndex: 'patient',
            key: 'patient',
            render: (text, record) => (
                <Link
                    className="block font-sans text-sm antialiased font-medium text-[#10b981]"
                    to={`/patient/${record.patient.id}`} // Use patient.id in the URL
                >
                    {record.patient.name} {/* Safely access the patient name */}
                </Link>
            ),
        },
        {
            title: 'Врач',
            dataIndex: 'doctor_name',
            key: 'doctor_name',
        },
        {
            title: 'Сана',
            dataIndex: 'date_at',
            key: 'date_at',
        },
        {
            title: 'Холати',
            dataIndex: 'status',
            key: 'status',
            render: (status) => (
                <Tag bordered={false} className="px-5 py-1 flex gap-2 max-w-max items-center" color={statusColors[status]}>
                    <img
                        className="w-6 h-6"
                        src={status && status !== 'closed' ? '/check.svg' : '/noncheck.svg'}
                        alt={status && status !== 'closed' ? 'Not Closed' : 'Closed'}
                    />
                    {statusLabels[status]}
                </Tag>
            )
        },

        {
            title: "Харакат",
            key: "action",
            render: (text, record) => (
                <span className="flex items-center gap-1">
          <Tooltip color="orange" title="Бемор картаси">
   <Link to={`/patient/${record.patient_id}`}
         className="group flex h-8 items-center justify-center rounded-md border border-orange-600 bg-gradient-to-b from-orange-400 via-orange-500 to-orange-600 px-1.5 text-neutral-50 shadow-[inset_0_1px_0px_0px_#fdba74] active:[box-shadow:none]"
   >
    <span className="block group-active:[transform:translate3d(0,1px,0)]">
      <HiDocumentMagnifyingGlass size="20" style={{color: '#fff'}}/>
    </span>
  </Link>
          </Tooltip>

 <Tooltip color="#e53935" title="Қабулни тугатиш">
                     <button onClick={() => openModal(record.id)}
                         className="group flex h-8 items-center justify-center rounded-md border border-red-600 bg-gradient-to-b from-red-400 via-red-500 to-red-600 px-1.5 text-neutral-50 shadow-[inset_0_1px_0px_0px_#f87171] active:[box-shadow:none]"
                     >
    <span className="block group-active:[transform:translate3d(0,1px,0)]">
           <FaHandshakeAngle  size="20" style={{color: '#fff'}}/>

    </span>
  </button>
 </Tooltip>

        </span>
            ),
        },
    ];

    return (
        <div className="p-5">
            <h1 className="text-xl">
                {userData ? (
                    <div className="uk-alert" uk-alert>
                        <div>
                            <div className="flex font-semibold text-lg items-center gap-2">
                                {userData.name || 'N/A'}
                            </div>
                            <div className="flex text-base text-gray-500 font-normal items-center gap-2">
                                Врач-стоматолог, ортодонт, терапевт
                            </div>
                        </div>
                    </div>


                ) : (
                    <p>No user data found in localStorage.</p>
                )}
            </h1>
            <Table
                rowClassName="cursor-pointer"
                columns={columns}
                dataSource={visits}
                size="small"
                loading={loading}
                rowKey="id" // Assuming each visit has a unique 'id'
                pagination={{pageSize: 10}} // Optional: Adjust pagination as needed
            />

            <Dialog open={isModalOpen} onClose={closeModal}>
                <div className="fixed inset-0 z-50 flex items-start justify-center pt-6">
                    {/* Фон */}
                    <div className="fixed inset-0 bg-black bg-opacity-20" onClick={closeModal}></div>

                    {/* Модальное окно */}
                    <Dialog.Panel
                        className={`transform transition-all duration-300 bg-white rounded-lg p-4 max-w-xs w-full mx-auto shadow-lg
                ${isModalOpen ? "translate-y-0 opacity-100" : "-translate-y-20 opacity-0"}`}
                    >
                        <Dialog.Title className="text font-semibold text-red-600">Тасдиқлаш</Dialog.Title>
                        <Dialog.Description className="mt-1 text-sm text-gray-700">
                            Сиз аниқ бўлсангиз, қабулни тугатишни истайсизми?
                        </Dialog.Description>

                        <div className="mt-3 flex justify-between">
                            <button
                                onClick={closeModal}
                                className="relative h-10 overflow-hidden rounded-md border border-neutral-200 bg-transparent px-4 text-sm font-medium text-neutral-950 before:absolute before:left-0 before:top-0 before:block before:h-full before:w-full before:-translate-y-full before:bg-neutral-100 before:transition-transform hover:before:translate-y-0"
                            >
                                <span className="relative">Бекор қилиш</span>
                            </button>
                            <button
                                onClick={confirmEndVisit}
                                className="bg-red-600 text-white px-4 py-2 text-sm font-medium rounded-md hover:bg-red-700"
                            >
                                Ҳа, тугатиш
                            </button>
                        </div>
                    </Dialog.Panel>
                </div>
            </Dialog>


        </div>
    );
};

export default DoctorVisits;
