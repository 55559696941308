import {useEffect} from 'react';
import {Routes, Route, useNavigate} from 'react-router-dom';
import {Homepage} from './pages/Homepage';
import {Notfoundpage} from './pages/Notfoundpage';
import {Layout} from './components/Layout'
import Roles from './pages/Roles';
import Patients from './pages/Patients/Patients.';
import Doctors from './pages/Doctors/Doctors';
import Services from './pages/Services';
import Partners from './pages/Partners';
import EpidemiologicalHistory from './pages/EpidemiologicalHistory/EpidemiologicalHistory';
import Mkb10 from './pages/Mkb10/Mkb10';
import Payments from './pages/Payments/Payments';
import DeptsLists from './pages/DeptsLists/DeptsLists';
import Admissions from './pages/Admissions/Admissions';
import PatientDetails from './pages/PatientDetails';
import 'suneditor/dist/css/suneditor.min.css';
import NewAdmissions from './pages/NewAdmissions/NewAdmissions';
import ReAdmissions from './pages/ReAdmissions/ReAdmissions';
import ListOfDisponser from './pages/ListOfDisponser/ListOfDisponser';
import Login from './auth/Login';
import {isLoggedIn, getUserRole} from './services/authServices';
import PatientAdmission from "./pages/PatientAdmission";
import PatientBioCard from './pages/PatientBioCard';
import PatientsReport from "./pages/Reports/PatientsReport";
import DoctorsReport from "./pages/Reports/DoctorsReport";
import FundsReport from "./pages/Reports/FundsReport";
import DoctorsReportDetail from "./pages/Reports/DoctorsReportDetail";
import FundsReportDetail from "./pages/Reports/FundsReportDetail";
import Apartaments from "./pages/Apartaments";
import StationaryTable from "./pages/StationaryList";
import StoreCategory from "./pages/Store/StoreCategory";
import ProtectedRoute from "./auth/ProtectedRoute";
import Warehouse from "./pages/Store/Warehouse";
import StoreRequest from "./pages/Store/StoreRequest";
import DoctorRequest from "./pages/Patients/DoctorRequest";
import DoctorVisits from "./pages/DoctorVisits";
import NewQueues from "./pages/Queue/NewQueues";
import AllDiscounts from "./pages/Discounts/AllDiscounts";
import Form43 from "./pages/Form43/Form43";
import ResendRequests from "./pages/Store/ResendRequests";
import Users from "./pages/Users/Users";

function PrivateRoute({children}) {
   const navigate = useNavigate();
   useEffect(() => {
      if (!isLoggedIn()) {
         navigate('/login');
      }
   }, [navigate]);

   return children;
}

function AdminRoute({children}) {
   const navigate = useNavigate();

   useEffect(() => {
      const checkAdmin = async () => {
         const userRole = await getUserRole();
         if (!userRole || !userRole.includes("admin")) {
            navigate('/');
         }
      };
      checkAdmin();
   }, [navigate]);

   return children;
}

function PublicRoute({children}) {
   const navigate = useNavigate();
   useEffect(() => {
      if (isLoggedIn()) {
         navigate('/');
      }
   }, [navigate]);

   return children;
}

function App() {

   return (
       <Routes>
          <Route path="/login" name="login" element={<PublicRoute><Login/></PublicRoute>}/>
          <Route path="/" element={<PrivateRoute><Layout/></PrivateRoute>}>
             <Route index element={<Homepage/>}/>
             {isLoggedIn() && (
                 <Route path="roles" element={<AdminRoute><Roles/></AdminRoute>}/>
             )}
             <Route path="patients"
                    element={<ProtectedRoute allowedRoles={['admin', 'financier', 'doctor', 'reception']}><Patients/></ProtectedRoute>}/>
              <Route path="queues"
                    element={<ProtectedRoute allowedRoles={['admin', 'financier', 'doctor', 'reception']}><NewQueues/></ProtectedRoute>}/>
             <Route path="/doctor/visits"
                    element={<ProtectedRoute allowedRoles={['admin', 'financier', 'doctor']}><DoctorVisits/></ProtectedRoute>}/>
             <Route path="/patient-old/:index"
                    element={<ProtectedRoute allowedRoles={['admin', 'financier', 'reception']}><PatientDetails/></ProtectedRoute>}/>
            <Route path="/patient/:index"
                    element={<ProtectedRoute allowedRoles={['admin', 'financier', 'doctor', 'reception']}><PatientBioCard/></ProtectedRoute>}/>
             <Route
                 path="/patient/:index/visit/:visitId"
                 element={
                    <ProtectedRoute allowedRoles={['admin', 'financier', 'doctor', 'reception']}>
                       <PatientBioCard />
                    </ProtectedRoute>
                 }
             />
             <Route path="/patient/admission/:index" element={<ProtectedRoute
                 allowedRoles={['admin', 'financier', 'doctor', 'reception']}><PatientAdmission/></ProtectedRoute>}/>
             <Route path="/catalog/doctors"
                    element={<ProtectedRoute allowedRoles={['admin']}><Doctors/></ProtectedRoute>}/>
              <Route path="/catalog/users"
                    element={<ProtectedRoute allowedRoles={['admin']}><Users/></ProtectedRoute>}/>
             <Route path="/catalog/services"
                    element={<ProtectedRoute allowedRoles={['admin']}><Services/></ProtectedRoute>}/>
             {/*<Route path="/apartaments" element={<Apartaments />} />*/}
             {/*<Route path="stationary" element={<StationaryTable />} />*/}
             <Route path="/catalog/partners"
                    element={<ProtectedRoute allowedRoles={['admin']}><Partners/></ProtectedRoute>}/>
             <Route path="/catalog/epidemiological_history"
                    element={<ProtectedRoute allowedRoles={['admin']}><EpidemiologicalHistory/></ProtectedRoute>}/>
              <Route path="/catalog/discount"
                    element={<ProtectedRoute allowedRoles={['admin', 'reception']}><AllDiscounts/></ProtectedRoute>}/>
             <Route path="/catalog/international-classification-of-diseases"
                    element={<ProtectedRoute allowedRoles={['admin']}><Mkb10/></ProtectedRoute>}/>
             <Route path="/payments/history"
                    element={<ProtectedRoute allowedRoles={['admin', 'financier', 'reception']}><Payments/></ProtectedRoute>}/>
             <Route path="/payments/debtors"
                    element={<ProtectedRoute allowedRoles={['admin', 'financier', 'reception']}><DeptsLists/></ProtectedRoute>}/>

             <Route path="/appointments/queue" element={<ProtectedRoute
                 allowedRoles={['admin', 'financier', 'doctor', 'reception']}><Admissions/></ProtectedRoute>}/>
             <Route path="/appointments/reappointments" element={<ProtectedRoute
                 allowedRoles={['admin', 'financier', 'doctor', 'reception']}><NewAdmissions/></ProtectedRoute>}/>
             <Route path="/appointments/followups" element={<ProtectedRoute
                 allowedRoles={['admin', 'financier', 'doctor', 'reception']}><ReAdmissions/></ProtectedRoute>}/>
             <Route path="/appointments/dispensary" element={<ProtectedRoute
                 allowedRoles={['admin', 'financier', 'doctor', 'reception']}><ListOfDisponser/></ProtectedRoute>}/>


             <Route path="/reports/patients"
                    element={<ProtectedRoute allowedRoles={['admin', 'financier']}><PatientsReport/></ProtectedRoute>}/>
             <Route path="/reports/patients"
                    element={<ProtectedRoute allowedRoles={['admin', 'financier']}><PatientsReport/></ProtectedRoute>}/>
             <Route path="/reports/doctors"
                    element={<ProtectedRoute allowedRoles={['admin', 'financier']}><DoctorsReport/></ProtectedRoute>}/>
             <Route path="/reports/finance"
                    element={<ProtectedRoute allowedRoles={['admin', 'financier']}><FundsReport/></ProtectedRoute>}/>
             <Route path="/reports/doctors/:doctorId" element={<ProtectedRoute
                 allowedRoles={['admin', 'financier']}><DoctorsReportDetail/></ProtectedRoute>}/>
             <Route path="/reports/finance/:date" element={<ProtectedRoute
                 allowedRoles={['admin', 'financier']}><FundsReportDetail/></ProtectedRoute>}/>
             <Route path="/store/category"
                    element={<ProtectedRoute allowedRoles={['admin', 'financier']}><StoreCategory/></ProtectedRoute>}/>
             <Route path="/store/warehouse"
                    element={<ProtectedRoute allowedRoles={['admin', 'financier']}><Warehouse/></ProtectedRoute>}/>
             <Route path="/store/request"
                    element={<ProtectedRoute allowedRoles={['admin', 'financier']}><StoreRequest/></ProtectedRoute>}/>  <Route path="/store/resend-requests"
                    element={<ProtectedRoute allowedRoles={['admin', 'financier']}><ResendRequests/></ProtectedRoute>}/>
             <Route path="/doctor/request"
                    element={<ProtectedRoute allowedRoles={['doctor']}><DoctorRequest/></ProtectedRoute>}/>  
              <Route path="/form43"
                    element={<ProtectedRoute allowedRoles={['admin']}><Form43/></ProtectedRoute>}/>
             <Route path="*" element={<Notfoundpage/>}/>
          </Route>
       </Routes>

   );
}

export default App;
