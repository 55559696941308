import React, { useEffect, useState } from 'react';
import { Modal, Input, Button, Form, Select, Table, Tooltip, Typography, Space, message } from 'antd';
import { UserAddOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import axiosInstance from "../../axios/axiosInstance";
import * as yup from 'yup';
import { useFormik } from 'formik';
import InputMask from "react-input-mask";
import {TbTrashFilled} from "react-icons/tb";
import {FaUserEdit} from "react-icons/fa";
import {MdEditDocument} from "react-icons/md";

const { Option } = Select;
const TABLE_HEAD = [ "#","ФИО", "Логин", "Телефон", "Роль", "Харакат"];

const createValidationSchema = yup.object({
  name: yup.string().required('ФИО майдони тулдириши шарт'),
  login: yup.string().required('Логин майдони тулдириши шарт'),
  password: yup.string().required('Пароль майдони тулдириши шарт'),
  confirmPassword: yup.string()
      .oneOf([yup.ref('password'), null], 'Пароллар бир хил булиши шарт')
      .required('Паролни саклаш шарт'),
  phone: yup.string().required('Телефон раками тулдириши шарт'),
});

const editValidationSchema = yup.object({
  name: yup.string().required('ФИО майдони тулдириши шарт'),
  phone: yup.string().required('Телефон раками тулдириши шарт'),
});

export default function Doctors() {
  const [users, setUsers] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editUser, setEditUser] = useState(null);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: "",
      login: "",
      password: "",
      confirmPassword: "",
      roles: [],
      phone: ""
    },
    validationSchema: editUser ? editValidationSchema : createValidationSchema,
    onSubmit: (values) => {
      if (editUser) {
        updateUser(editUser.id, values);
      } else {
        createUser(values);
      }
    },
  });

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get("/admin/users");
      const doctorUsers = response.data.data.filter(user => user.roles.includes('doctor'));
      setUsers(doctorUsers);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  const createUser = async (userData) => {
    setLoading(true);
    try {
      // Remove non-numeric characters from the phone number
      const formattedPhone = userData.phone.replace(/\D/g, ''); // Removes non-numeric characters
      const newUser = {
        ...userData,
        phone: formattedPhone,  // Set the formatted phone number
        roles: 'doctor'  // Set the role to doctor explicitly
      };
      const response = await axiosInstance.post('/admin/users', newUser);
      message.success('User created successfully');
      setUsers([...users, response.data.data]);
      closeModal();
    } catch (error) {
      console.error('Error creating user:', error);
      if (error.response && error.response.data && error.response.data.message) {
        formik.setFieldError('phone', 'Телефон ракам руйхатдан утказган');
      }
    } finally {
      setLoading(false);
    }
  };

  const updateUser = async (userId, updatedUserData) => {
    setLoading(true);
    try {
      // Remove non-numeric characters from the phone number
      const formattedPhone = updatedUserData.phone.replace(/\D/g, ''); // Removes non-numeric characters

      const fieldsToUpdate = {
        ...updatedUserData,
        phone: formattedPhone,  // Set the formatted phone number
        roles: 'doctor'  // Always set the role to doctor
      };

      const response = await axiosInstance.put(`/admin/users/${userId}`, fieldsToUpdate);
      message.success('User updated successfully');
      setUsers(users.map(user => (user.id === userId ? response.data.data : user)));
      closeModal();
    } catch (error) {
      console.error('Error updating user:', error);
    } finally {
      setLoading(false);
    }
  };

  const deleteUser = async (userId) => {
    setLoading(true);
    try {
      await axiosInstance.delete(`/admin/users/${userId}`);
      message.success('User deleted successfully');
      setUsers(users.filter(user => user.id !== userId));
    } catch (error) {
      console.error('Error deleting user:', error);
    } finally {
      setLoading(false);
    }
  };

  const closeModal = () => {
    setIsModalVisible(false);
    setEditUser(null);
    formik.resetForm();
  };

  const openModal = () => {
    setIsModalVisible(true);
  };

  const openEditDialog = (user) => {
    setEditUser(user);
    formik.setValues({
      name: user.name,
      login: user.login,
      password: "",
      confirmPassword: "",
      roles: user.roles.map(role => ({ label: role, value: role })),
      phone: user.phone
    });
    setIsModalVisible(true);
  };

  const columns = [
    { title: 'ФИО', dataIndex: 'name', key: 'name' },
    { title: 'Логин', dataIndex: 'login', key: 'login' },
    { title: 'Телефон', dataIndex: 'phone', key: 'phone' },
    { title: 'Роль', dataIndex: 'roles', key: 'roles', render: (roles) => roles.join(', ') },
    {
      title: 'Харакат',
      key: 'action',
      render: (_, user) => (
          <Space size="small">
            <Tooltip color="#59b55d" title="Врачни ўзгартириш">
              <button onClick={() => openEditDialog(user)}
                      className="group flex h-8 items-center justify-center rounded-md border border-green-600 bg-gradient-to-b from-green-400 via-green-500 to-green-600 px-1.5 text-neutral-50 shadow-[inset_0_1px_0px_0px_#34d399] active:[box-shadow:none]"
              >
    <span className="block group-active:[transform:translate3d(0,1px,0)]">
      <MdEditDocument size="20" style={{color: '#fff'}}/>
    </span>
              </button>
            </Tooltip>
            <Tooltip color="#f23e39" title="Удалить">
              <button onClick={() => deleteUser(user.id)}
                  className="group flex h-8 items-center justify-center rounded-md border border-red-600 bg-gradient-to-b from-red-400 via-red-500 to-red-600 px-1.5 text-neutral-50 shadow-[inset_0_1px_0px_0px_#f87171] active:[box-shadow:none]"
              >
    <span className="block group-active:[transform:translate3d(0,1px,0)]">
      <TbTrashFilled  size="20" style={{color: '#fff'}}/>
    </span>
              </button>
            </Tooltip>
          </Space>
      ),
    },
  ];

  return (
      <div>
        <div className="px-10 flex items-center justify-between" style={{marginBottom: 16}}>
          <h1 className="text-xl font-semibold mb-3">Докторлар</h1>
          <Button type="primary" onClick={openModal} icon={<UserAddOutlined/>}>
            Янги қўшиш
          </Button>
        </div>

        <Table size="small" columns={columns} dataSource={users} loading={loading} rowKey="id" />

        <Modal
            title={editUser ? "Доктор малумотларни Узгартириш" : "Янги доктор кушиш"}
            visible={isModalVisible}
            onCancel={closeModal}
            onOk={formik.handleSubmit}
            confirmLoading={loading}
        >
          <Form layout="vertical">
            <Form.Item label="ФИО" validateStatus={formik.touched.name && formik.errors.name ? 'error' : ''}>
              <Input
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
              />
              {formik.touched.name && formik.errors.name && <Typography.Text type="danger">{formik.errors.name}</Typography.Text>}
            </Form.Item>
            <Form.Item label="Логин" validateStatus={formik.touched.login && formik.errors.login ? 'error' : ''}>
              <Input
                  name="login"
                  value={formik.values.login}
                  onChange={formik.handleChange}
              />
              {formik.touched.login && formik.errors.login && <Typography.Text type="danger">{formik.errors.login}</Typography.Text>}
            </Form.Item>
            {!editUser && (
                <>
                  <Form.Item label="Пароль" validateStatus={formik.touched.password && formik.errors.password ? 'error' : ''}>
                    <Input.Password
                        name="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                    />
                    {formik.touched.password && formik.errors.password && <Typography.Text type="danger">{formik.errors.password}</Typography.Text>}
                  </Form.Item>
                  <Form.Item label="Паролни такрорланг" validateStatus={formik.touched.confirmPassword && formik.errors.confirmPassword ? 'error' : ''}>
                    <Input.Password
                        name="confirmPassword"
                        value={formik.values.confirmPassword}
                        onChange={formik.handleChange}
                    />
                    {formik.touched.confirmPassword && formik.errors.confirmPassword && <Typography.Text type="danger">{formik.errors.confirmPassword}</Typography.Text>}
                  </Form.Item>
                </>
            )}
            <Form.Item label="Телефон раками" validateStatus={formik.touched.phone && formik.errors.phone ? 'error' : ''}>
              <InputMask
                  mask="+\9\9\8 (99) 999-99-99"
                  maskChar="_"
                  className="h-10"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  name="phone"
              >
                {(inputProps) => (
                    <Input
                        {...inputProps}
                        prefix={<img src="/icons8-узбекистан-48.png" className="w-6 h-6" />}
                        placeholder="+998 (__) ___-__-__"
                    />
                )}
              </InputMask>
              {formik.touched.phone && formik.errors.phone && (
                  <Typography.Text type="danger">{formik.errors.phone}</Typography.Text>
              )}
            </Form.Item>

          </Form>
        </Modal>
      </div>
  );
}
