import React, {useEffect, useState} from 'react';
import {Button, Input, message, Modal, Table, Tooltip, Select, Drawer, Tag, Space} from "antd";
import {DeleteOutlined, EditOutlined, PlusOutlined} from "@ant-design/icons";
import axiosInstance from "../../axios/axiosInstance";
import {MdEditDocument} from "react-icons/md";
import {TbAlignBoxRightMiddleFilled, TbTrashFilled} from "react-icons/tb";
import {ArchiveBoxIcon, EyeIcon} from "@heroicons/react/24/solid";
import {FaBoxesPacking} from "react-icons/fa6";
const { Option } = Select;
function Warehouse(props) {
   const [requests, setRequests] = useState([]);
   const [categories, setCategories] = useState([]); // State to hold categories
   const [isOpen, setIsOpen] = useState(false);
   const [modalMode, setModalMode] = useState('add'); // 'add' or 'edit'
   const [selectedRequest, setSelectedRequest] = useState(null);
   const [selectedQty, setSelectedQty] = useState(null);
   const [data, setData] = useState({
      category_id: null,
      name: '',
      qty: '',
      price: ''
   });
   const [isHistoryDrawerVisible, setIsHistoryDrawerVisible] = useState(false); // For drawer visibility
   const [selectedHistory, setSelectedHistory] = useState([]); // Store selected item's history
   const [selectedCategory, setSelectedCategory] = useState(null); // Track selected category for filtering
   const [loading, setLoading] = useState(false);

   const [pagination, setPagination] = useState({
      current: 1,
      pageSize: 20,
      total: 0,
   });

   const openHistoryDrawer = (histories, qty) => {
      setSelectedHistory(histories);
      setSelectedQty(qty);
      setIsHistoryDrawerVisible(true);
   };

   const closeHistoryDrawer = () => {
      setIsHistoryDrawerVisible(false); // Close the drawer
   };

   const fetchRequests = async (page = 1) => {
      setLoading(true);
      try {
         const params = {
            page,
            category_id: selectedCategory || undefined,
         };
         const response = await axiosInstance.get('/financiers/warehouses', { params });
         const { data, meta } = response.data;
         setRequests(data);
         setPagination({
            current: meta.current_page,
            pageSize: meta.per_page,
            total: meta.total,
         });
      } catch (error) {
         console.error('Error fetching requests:', error);
         message.error('Failed to load data');
      } finally {
         setLoading(false);
      }
   };


   // Fetch the list of categories
   const fetchCategories = async () => {
      try {
         const response = await axiosInstance.get('/financiers/categories');
         setCategories(response.data.data);
      } catch (error) {
         console.error('Error fetching categories:', error);
         message.error('Error fetching categories');
      }
   };

   useEffect(() => {
      fetchRequests(pagination.current);
      fetchCategories(); // Fetch categories when component loads
   }, [selectedCategory]);

   const handleTableChange = (pagination) => {
      fetchRequests(pagination.current);
   };

   const handleCategoryChange = (value) => {
      setSelectedCategory(value);
   };

   const openModal = (mode, request = null) => {
      setModalMode(mode);
      if (mode === 'edit' && request) {
         setSelectedRequest(request);

         setData({
            category_id: request.category.id,
            name: request.name,
            qty: request.qty,
            price: request.price
         });
      } else {
         setData({category_id: null, name: '', qty: '', price: ''});
      }
      setIsOpen(true);
   };

   const closeModal = () => {
      setIsOpen(false);
      setSelectedRequest(null);
      setData({category_id: null, name: '', qty: '', price: ''});
   };

   // POST request to add a new store request
   const addRequest = async () => {
      try {
         const response = await axiosInstance.post('/financiers/warehouses', data);
         setRequests([...requests, response.data.data]);
         message.success('Request added successfully');
         closeModal();
      } catch (error) {
         console.error('Error adding request:', error);
         message.error('Error adding request');
      }
   };

   // PUT request to update an existing store request
   const updateRequest = async () => {
      try {
         const response = await axiosInstance.put(`/financiers/warehouses/${selectedRequest.id}`, data);
         setRequests(requests.map((req) => (req.id === selectedRequest.id ? response.data.data : req)));
         message.success('Махсулот муваффақиятли ўзгарди');
         closeModal();
      } catch (error) {
         console.error('Error updating request:', error);
         message.error('Error updating request');
      }
   };

   // DELETE request to delete an existing store request
   const deleteRequest = async (requestId) => {
      try {
         await axiosInstance.delete(`/financiers/warehouses/${requestId}`);
         setRequests(requests.filter((req) => req.id !== requestId));
         message.success('Request deleted successfully');
      } catch (error) {
         console.error('Error deleting request:', error);
         message.error('Error deleting request');
      }
   };

   // Logic to either add or update the request based on modal mode
   const handleOk = async () => {
      if (!data.category_id || !data.name || !data.qty ) {
         message.error('Please fill all fields');
         return;
      }
      if (modalMode === 'add') {
         await addRequest();
      } else {
         await updateRequest();
      }
   };

   const columns = [
      {
         title: 'ID',
         dataIndex: 'id',
         key: 'id',
      },
      {
         title: 'Категория',
         dataIndex: 'category',
         key: 'category_id',
         render: (category) => {
            return category.name;
         }
      },
      {
         title: 'номи',
         dataIndex: 'name',
         key: 'name',
      },
      {
         title: 'Миқдор',
         dataIndex: 'qty',
         key: 'qty',
      },
      {
         title: 'Нархи',
         dataIndex: 'price',
         key: 'price',
         render: (text, record) => <span>{new Intl.NumberFormat('ru-UZ').format(record.price)} сўм</span>
      },
      {
         title: 'Умуммий',
         render: (text, record) => <span>{new Intl.NumberFormat('ru-UZ').format(record.qty * record.price)} сўм</span>
      },

      {
         title: 'Сўровнома тариҳи',
         key: 'histories',
         render: (_, record) => (
             <button   onClick={() => openHistoryDrawer(record.histories, record.qty)}
                 className="group flex h-7 items-center justify-center rounded mx-auto border border-blue-600 bg-gradient-to-b from-blue-400 via-blue-500 to-blue-600 px-1.5 text-neutral-50 shadow-[inset_0_1px_0px_0px_#60a5fa] active:[box-shadow:none]"
             >
    <span className="flex items-center gap-1 text-white text-[13px] group-active:[transform:translate3d(0,1px,0)]">
       <EyeIcon className="w-4 h-4" />
      Кўриш
    </span>
             </button>
         )
      },
      {
         title: 'Ҳаракат',
         key: 'action',
         render: (text, record) => (
             <div>

                <Space size="small">
                   <Tooltip color="#59b55d" title="Ўзгартириш">
                      <button onClick={() => openModal('edit', record)}
                              className="group flex h-8 items-center justify-center rounded-md border border-green-600 bg-gradient-to-b from-green-400 via-green-500 to-green-600 px-1.5 text-neutral-50 shadow-[inset_0_1px_0px_0px_#34d399] active:[box-shadow:none]"
                      >
    <span className="block group-active:[transform:translate3d(0,1px,0)]">
      <MdEditDocument size="20" style={{color: '#fff'}}/>
    </span>
                      </button>
                   </Tooltip>
                   <Tooltip color="#f23e39" title="Удалить">
                      <button  onClick={() => deleteRequest(record.id)}
                              className="group flex h-8 items-center justify-center rounded-md border border-red-600 bg-gradient-to-b from-red-400 via-red-500 to-red-600 px-1.5 text-neutral-50 shadow-[inset_0_1px_0px_0px_#f87171] active:[box-shadow:none]"
                      >
    <span className="block group-active:[transform:translate3d(0,1px,0)]">
      <TbTrashFilled  size="20" style={{color: '#fff'}}/>
    </span>
                      </button>
                   </Tooltip>
                </Space>
             </div>
         ),
      },
   ];

   const renderStatusTag = (status) => {
      if (!status) {
         return <Tag  className="px-3 py-1 h-8  flex gap-2 max-w-max items-center" color="#bdbdbd"> <img
             className="w-6 h-6"
             src='/check.svg'
         /> Ноаниқ</Tag>;
      }
      switch (status) {
         case 'process':
            return <Tag  className="px-3 py-1 h-8  flex gap-2 max-w-max items-center" color="#3C72F9"> <img
                className="w-6 h-6"
                src='/check.svg'
            />Жараёнда</Tag>;
         case 'accept':
            return <Tag className="px-3 py-1 h-8  flex gap-2 max-w-max items-center" color="#4AC000"> <img
                className="w-6 h-6"
                src='/check.svg'
            />Қабул қилинди</Tag>;
         case 'cancel':
            return <Tag className="px-3 py-1 h-8  flex gap-2 max-w-max items-center" color="#ff3a3a"> <img
                className="w-6 h-6"
                src='/noncheck.svg'
            />Рад этилди</Tag>;
         default:
            return <Tag className="px-3 py-1 h-8  flex gap-2 max-w-max items-center" color="#bdbdbd"> <img
                className="w-6 h-6"
                src='/check.svg'
            />Ноаниқ</Tag>;
      }
   };

   // Drawer columns for histories
   const historyColumns = [
      {
         title: 'ID',
         dataIndex: 'id',
         key: 'id',
      },
      {
         title: 'Миқдор',
         dataIndex: 'qty',
         key: 'qty',
         render: (qty) => <div className={'flex items-center'}>-{qty}</div>
      },
      {
         title: 'Холати',
         dataIndex: 'status',
         key: 'status',
         render: (status) =>  renderStatusTag(status)
      },
      {
         title: 'Сана',
         dataIndex: 'created_at',
         key: 'created_at',
      },
      {
         title: 'Юборувчи',
         dataIndex: 'sender',
         key: 'sender',
         render: (sender) => sender.name,
      },
      {
         title: 'Қабул қилувчи',
         dataIndex: 'receive',
         key: 'receive',
         render: (receive) => receive ? receive.name : 'Қабул қилинмаган',
      },
      {
         title: 'Изоҳ',
         dataIndex: 'history',
         key: 'comment',
         render: (history) => history?.comment ? <p className="capitalize" style={{color: '#00AA81'}}>{history?.comment}</p> : 'Изоҳ йўқ',
      },
   ];

   return (
       <div>
          <div className="flex px-10 justify-between items-center">
             <h1 className="text-xl font-semibold ">Склад Маҳсулот</h1>

          </div>
      <div className="flex justify-between items-center mb-4 gap-2 px-10">
         <Select
             style={{ width: 200 }}
             placeholder="Категорияни танланг"
             onChange={handleCategoryChange}
             allowClear
         >
            {categories.map((category) => (
                <Option key={category.id} value={category.id}>
                   {category.name}
                </Option>
            ))}
         </Select>

         <Button className="flex items-center" type="primary" icon={<FaBoxesPacking size="20" />} onClick={() => openModal('add')}>
            Янги қўшиш
         </Button>

      </div>
          <Table  loading={loading}  size="small" rowClassName="even:bg-gray-50/25" columns={columns} dataSource={requests} rowKey="id"  pagination={{
             current: pagination.current,
             pageSize: pagination.pageSize,
             total: pagination.total,
          }}
                  onChange={handleTableChange}/>

          {/* Drawer for showing history */}
          <Drawer
              title={`Тарихи (Миқдор: ${selectedQty || 0})`}
              placement="bottom"
              width={800}
              onClose={closeHistoryDrawer}
              open={isHistoryDrawerVisible}
              // width={500}
          >
             <Table size="small" columns={historyColumns} dataSource={selectedHistory} rowKey="id" pagination={false}/>
          </Drawer>

          <Modal
              title={modalMode === 'add' ? 'Янги қўшиш' : 'ўзгартириш'}
              open={isOpen}
              onCancel={closeModal}
              onOk={handleOk}
              okText={modalMode === 'add' ? 'Янги қўшиш' : 'ўзгартириш'}
          >
             <Select
                 placeholder="категория"
                 value={data.category_id}
                 onChange={(value) => setData({...data, category_id: value})}
                 style={{width: '100%', marginBottom: 16}}
             >
                {categories.map((category) => (
                    <Select.Option key={category.id} value={category.id}>
                       {category.name}
                    </Select.Option>
                ))}
             </Select>
             <Input
                 placeholder="номи"
                 value={data.name}
                 onChange={(e) => setData({...data, name: e.target.value})}
                 style={{marginBottom: 16}}
             />
             <Input
                 placeholder="миқдор"
                 value={data.qty}
                 onChange={(e) => setData({...data, qty: e.target.value})}
                 style={{marginBottom: 16}}
             />
             <Input
                 placeholder="нархи"
                 value={data.price}
                 onChange={(e) => setData({...data, price: e.target.value})}
                 style={{marginBottom: 16}}
             />
          </Modal>
       </div>
   );
}

export default Warehouse;
