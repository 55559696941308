import React, { Fragment, useState, useEffect } from 'react';
import {Table, Button, Modal, Input, Tooltip, message, Space} from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import axiosInstance from "../axios/axiosInstance";
import {MdEditDocument} from "react-icons/md";
import {TbTrashFilled} from "react-icons/tb";

const Partners = () => {
  const [partners, setPartners] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [modalMode, setModalMode] = useState('add'); // 'add' or 'edit'
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [newPartnerName, setNewPartnerName] = useState('');
  const [newPartnerPhone, setNewPartnerPhone] = useState('');

  const fetchPartners = async () => {
    try {
      const response = await axiosInstance.get('/partners');
      setPartners(response.data.data);
    } catch (error) {
      console.error('Error fetching partners:', error);
    }
  };

  useEffect(() => {
    fetchPartners();
  }, []);

  const openModal = (mode, partner = null) => {
    setModalMode(mode);
    if (mode === 'edit' && partner) {
      setSelectedPartner(partner);
      setNewPartnerName(partner.name);
      setNewPartnerPhone(partner.phone);
    } else {
      setNewPartnerName('');
      setNewPartnerPhone('');
    }
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setSelectedPartner(null);
  };

  const deletePartner = async (partnerId) => {
    try {
      await axiosInstance.delete(`/partners/${partnerId}`);
      setPartners(partners.filter((partner) => partner.id !== partnerId));
      message.success('Partner deleted successfully');
    } catch (error) {
      console.error('Error deleting partner:', error);
      message.error('Error deleting partner');
    }
  };

  const addOrUpdatePartner = async () => {
    try {
      if (modalMode === 'add') {
        const response = await axiosInstance.post('/partners', {
          name: newPartnerName,
          phone: newPartnerPhone,
        });
        setPartners([...partners, response.data]);
        message.success('Partner added successfully');
      } else if (modalMode === 'edit' && selectedPartner) {
        await axiosInstance.put(`/partners/${selectedPartner.id}`, {
          name: newPartnerName,
          phone: newPartnerPhone,
        });
        setPartners(partners.map((partner) =>
            partner.id === selectedPartner.id
                ? { ...partner, name: newPartnerName, phone: newPartnerPhone }
                : partner
        ));
        message.success('Partner updated successfully');
      }
      closeModal();
      fetchPartners();
    } catch (error) {
      console.error('Error adding/updating partner:', error);
      message.error('Error adding/updating partner');
    }
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'ФИО',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Телефон рақами',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Харакат',
      key: 'action',
      render: (text, record) => (
          <Space  size="small">
            <Tooltip color="#59b55d" title="Ўзгартириш">
              <button  onClick={() => openModal('edit', record)}
                      className="group flex h-8 items-center justify-center rounded-md border border-green-600 bg-gradient-to-b from-green-400 via-green-500 to-green-600 px-1.5 text-neutral-50 shadow-[inset_0_1px_0px_0px_#34d399] active:[box-shadow:none]"
              >
    <span className="block group-active:[transform:translate3d(0,1px,0)]">
      <MdEditDocument size="20" style={{color: '#fff'}}/>
    </span>
              </button>
            </Tooltip>
            <Tooltip color="#f23e39" title="Хамкорни ўчириш">
              <button onClick={() => deletePartner(record.id)}
                      className="group flex h-8 items-center justify-center rounded-md border border-red-600 bg-gradient-to-b from-red-400 via-red-500 to-red-600 px-1.5 text-neutral-50 shadow-[inset_0_1px_0px_0px_#f87171] active:[box-shadow:none]"
              >
    <span className="block group-active:[transform:translate3d(0,1px,0)]">
      <TbTrashFilled  size="20" style={{color: '#fff'}}/>
    </span>
              </button>
            </Tooltip>

          </Space>
      ),
    },
  ];

  return (
      <div>
        <div className="flex px-10 justify-between items-center mb-4">
          <h1 className="text-xl font-semibold mb-3">Барча Хамкорлар</h1>
          <Button type="primary" icon={<PlusOutlined/>} onClick={() => openModal('add')}>
            Янги қўшиш
          </Button>
        </div>
        <Table size="small"  columns={columns} dataSource={partners} rowKey="id" pagination={{ pageSize: 10 }} />

        <Modal
            title={modalMode === 'add' ? 'Ҳамкор қўшиш' : 'Ҳамкорни ўргатириш'}
            visible={isOpen}
            onCancel={closeModal}
            onOk={addOrUpdatePartner}
            okText={modalMode === 'add' ? 'Қўшиш' : 'Янгилаш'}
        >
          <Input
              placeholder="ФИО"
              value={newPartnerName}
              onChange={(e) => setNewPartnerName(e.target.value)}
              style={{ marginBottom: 16 }}
          />
          <Input
              placeholder="Телефон рақами"
              value={newPartnerPhone}
              onChange={(e) => setNewPartnerPhone(e.target.value)}
          />
        </Modal>
      </div>
  );
};

export default Partners;

