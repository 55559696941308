import React, { useEffect } from 'react';
import { Modal, Form, Input, Button, Select, DatePicker, Upload, Radio } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import LocationSelect from "../../../components/LocationSelect";
import DoctorsSelect from "./DoctorsList";
import moment from 'moment';
import { BiInfoCircle } from "react-icons/bi";
import InputMask from 'react-input-mask';
import {PhoneArrowDownLeftIcon} from "@heroicons/react/24/solid";

const { Option } = Select;

const PatientFormModal = ({ visible, onClose, onSubmit, loading, initialValues }) => {
    const [form] = Form.useForm();

    useEffect(() => {
        if (initialValues) {
            form.setFieldsValue({
                ...initialValues,
                location: {
                    province_id: initialValues.province_id,
                    district_id: initialValues.district_id,
                },
                birth_at: initialValues.birth_at ? moment(initialValues.birth_at) : null,
            });
        } else {
            form.resetFields();
        }
    }, [initialValues, form]);

    useEffect(() => {
        if (!visible) {
            form.resetFields();
        }
    }, [visible, form]);

    const handleFinish = (values) => {
        onSubmit(values);
    };

    return (
        <Modal
            centered
            width={1000}
            visible={visible}
            title={initialValues ? `Ўзгартириш ⟶ ${initialValues.name}` : "Янги бемор қушиш"}
            onCancel={onClose}
            footer={null}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={handleFinish}
            >
                <div className="grid grid-cols-3 gap-2">
                    <Form.Item
                        name="name"
                        label="Бемор ФИО"
                        rules={[{ required: true, message: 'Илтимос бемор номини киритинг!' }]}
                    >
                        <Input placeholder="Бемор ФИО" className="h-10" />
                    </Form.Item>
                    <Form.Item
                        className="col-span-2"
                        label="Яшаш Манзили"
                        name="location"
                        rules={[{ required: true, message: 'Илтимос яшаш манзилини киритинг!' }]}
                    >
                        <LocationSelect />
                    </Form.Item>
                    <Form.Item
                        name="partner_id"
                        label="Хамкор"
                        rules={[{ required: true, message: 'Илтимос хамкорни танланг' }]}
                    >
                        <DoctorsSelect />
                    </Form.Item>
                    <Form.Item
                        tooltip={{
                            title: 'Тугри телефон рақамни киритинг код билан',
                            icon: <BiInfoCircle />,
                        }}
                        name="phone"
                        label="Телефон раками"
                        rules={[
                            { required: true, message: 'Илтимос телефон ракамини киритинг' }
                        ]}
                    >
                        <InputMask
                            placeholder="+998 (__) ___-__-__"
                            mask="+\9\9\8 (99) 999-99-99"
                            maskChar="_"
                            minLength="12"
                            className="h-10"
                            onChange={(e) => {
                                const { value } = e.target;
                                const formattedValue = value.replace(/[^\d+]/g, '');
                                e.target.value = formattedValue;
                            }}
                        >
                            {(inputProps) => <Input prefix={<img src="/icons8-узбекистан-48.png" className="w-6 h-6"/>} {...inputProps} />}
                        </InputMask>
                    </Form.Item>
                    <Form.Item
                        name="profession"
                        label="Касби"
                        rules={[{ required: false, message: 'Илтимос касбини киритинг' }]}
                    >
                        <Input placeholder="Касби" className="h-10" />
                    </Form.Item>

                    <Form.Item
                        name="birth_at"
                        label="Тугилан куни"
                        rules={[{ required: true, message: 'Илтимос Тугилган кунини танланг' }]}
                    >
                        <DatePicker format="DD-MM-YYYY" className="w-full h-10" />
                    </Form.Item>
                    <Form.Item
                        name="home_address"
                        label="Уй Манзили"
                        rules={[{ required: true, message: 'Илтимос уй манзилни киритинг' }]}
                    >
                        <Input placeholder="Уй Манзили" className="h-10" />
                    </Form.Item>
                    <Form.Item
                        name="work_address"
                        label="Иш Манзили"
                    >
                        <Input placeholder="Иш Манзили" className="h-10" />
                    </Form.Item>
                    <Form.Item
                        name="remark"
                        label="Изох"
                    >
                        <Input placeholder="Изох" className="h-10" />
                    </Form.Item>
                    <Form.Item
                        label="Жинси"
                        name="gender"
                        className="w-full"
                        rules={[{ required: true, message: 'Илтимос жинсини танланг!' }]}
                    >
                        <Radio.Group
                            size="middle"
                            optionType="button"
                            className="w-full items-center"
                            style={{ display: 'flex', justifyContent: 'space-between' }}
                        >
                            <Radio.Button className="h-10 flex items-center justify-center" value="men" style={{ flex: 1, textAlign: 'center' }}>
                                Еркак
                            </Radio.Button>
                            <Radio.Button className="h-10 flex items-center justify-center" value="women" style={{ flex: 1, textAlign: 'center' }}>
                                Аёл
                            </Radio.Button>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        name="file"
                        label="Расм"
                        className="w-full"
                    >
                        <Upload.Dragger
                            className="w-full"
                            name="file"
                            multiple={false}
                            beforeUpload={() => false}
                            showUploadList={false}
                        >
                            <p className="ant-upload-drag-icon">
                                <svg  xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 24 24"
                                     fill="none" stroke="#00AA81" stroke-width="1.6" stroke-linecap="round"
                                     stroke-linejoin="round" className="lucide lucide-image-up mx-auto">
                                    <path
                                        d="M10.3 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v10l-3.1-3.1a2 2 0 0 0-2.814.014L6 21"/>
                                    <path d="m14 19.5 3-3 3 3"/>
                                    <path d="M17 22v-5.5"/>
                                    <circle cx="9" cy="9" r="2"/>
                                </svg>
                            </p>
                            <p className="ant-upload-text">Файл юклаш</p>
                            <p className="ant-upload-hint">
                                Файлни бу ерга ташланг ёки юклаш учун шинамкликни босинг
                            </p>
                        </Upload.Dragger>
                    </Form.Item>
                </div>
                <Form.Item>
                    <Button className="w-full" type="primary" htmlType="submit" loading={loading}>
                        Бемор қўшиш
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default PatientFormModal;
