import React, {useEffect, useState} from 'react';
import axiosInstance from "../../axios/axiosInstance";
import {Button, Form, Input, message, Modal, Select, Table, Tag} from "antd";
import {MdAssignmentReturn} from "react-icons/md";

function DoctorRequest(props) {
   const [requests, setRequests] = useState([])
   const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
   const [isReturnModalVisible, setIsReturnModalVisible] = useState(false);
   const [warehouses, setWarehouses] = useState([]);
   const [form] = Form.useForm();
   const [loading, setLoading] = useState(false);
   const [selectedRequest, setSelectedRequest] = useState(null);
   const [isModalVisible, setIsModalVisible] = useState(false);
   const [isDescriptionModalVisible, setIsDescriptionModalVisible] = useState(false);
   const [fullDescription, setFullDescription] = useState('');
   const [returnForm] = Form.useForm();
   const [selectedWarehouse, setSelectedWarehouse] = useState(null);
   const [pagination, setPagination] = useState({
      current: 1,
      pageSize: 20,
      total: 0,
   });

   const qtyValidator = (_, value) => {
      if (value && value > 100) {
         return Promise.reject(new Error('Миқдор 100 дан ошмаслиги керак!'));
      }
      return Promise.resolve();
   };

   const openReturnModal = (request) => {
      setSelectedRequest(request);
      setIsReturnModalVisible(true);
   };

   const closeReturnModal = () => {
      setIsReturnModalVisible(false);
      setSelectedRequest(null);
      returnForm.resetFields();
   };

   const openModal = (request) => {
      setSelectedRequest(request);
      setIsModalVisible(true);
   };

   const closeModal = () => {
      setIsModalVisible(false);
      setSelectedRequest(null);
   };

   const handleReturnRequest = async (values) => {
      try {
         const payload = {
            application_id: selectedRequest.id,
            qty: values.qty,
            comment: values.comment || null,
         };
         await axiosInstance.post('/doctor/return_warehouses', payload);
         message.success('Товар успешно возвращён');
         closeReturnModal();
         getRequests();
      } catch (error) {
         console.error('Error returning product:', error);
         message.error('Ошибка при возврате товара');
      }
   };

   const getRequests = async (page = 1) => {
      setLoading(true);
      try {
         const response = await axiosInstance.get(`/doctor/application/paginate?page=${page}`);
         const { data, meta } = response.data;
         setRequests(data);
         setPagination({
            current: meta.current_page,
            pageSize: meta.per_page,
            total: meta.total,
         });
      } catch (error) {
         console.error("Error fetching requests:", error);
         message.error("Error fetching data");
      } finally {
         setLoading(false);
      }
   };

   // Fetch available warehouses for the create modal
   const fetchWarehouses = async () => {
      try {
         const response = await axiosInstance.get('/warehouse/all');
         setWarehouses(response.data.data);
      } catch (error) {
         console.error('Error fetching warehouses:', error);
         message.error('Error fetching warehouses');
      }
   };

   useEffect(() => {
      getRequests();
      fetchWarehouses();
   }, []);

   const renderStatusTag = (status) => {
      if (!status) {
         return <Tag  className="px-3 py-1 h-8  flex gap-2 max-w-max items-center" color="#bdbdbd"> <img
             className="w-6 h-6"
             src='/check.svg'
         /> Ноаниқ</Tag>;
      }
      switch (status) {
         case 'process':
            return <Tag  className="px-3 py-1 h-8  flex gap-2 max-w-max items-center" color="#3C72F9"> <img
                className="w-6 h-6"
                src='/check.svg'
            />Жараёнда</Tag>;
         case 'accept':
            return <Tag className="px-3 py-1 h-8  flex gap-2 max-w-max items-center" color="#4AC000"> <img
                className="w-6 h-6"
                src='/check.svg'
            />Қабул қилинди</Tag>;
         case 'cancel':
            return <Tag className="px-3 py-1 h-8  flex gap-2 max-w-max items-center" color="#ff3a3a"> <img
                className="w-6 h-6"
                src='/noncheck.svg'
            />Рад этилди</Tag>;
         default:
            return <Tag className="px-3 py-1 h-8  flex gap-2 max-w-max items-center" color="#bdbdbd"> <img
                className="w-6 h-6"
                src='/check.svg'
            />Ноаниқ</Tag>;
      }
   };

   const openCreateModal = () => {
      setIsCreateModalVisible(true);
   };

   const closeCreateModal = () => {
      setIsCreateModalVisible(false);
      form.resetFields();
   };

   const openDescriptionModal = (desc) => {
      setFullDescription(desc);
      setIsDescriptionModalVisible(true);
   };

   const closeDescriptionModal = () => {
      setIsDescriptionModalVisible(false);
      setFullDescription('');
   };

   // Function to handle new request creation
   const handleCreateRequest = async (values) => {
      try {
         await axiosInstance.post('/doctor/applications', values);
         message.success('Request created successfully');
         setIsCreateModalVisible(false);
         form.resetFields();
         getRequests(); // Refresh the request list
      } catch (error) {
         console.error('Error creating request:', error);
         message.error('Error creating request');
      }
   };

   const columns = [
      {
         title: 'ID',
         dataIndex: 'id',
         key: 'id',
      },
      {
         title: 'Миқдор',
         dataIndex: 'qty',
         key: 'qty',
      },
      {
         title: 'Сана',
         dataIndex: 'created_at',
         key: 'created_at',
      },
      // {
      //    title: 'Юборувчи',
      //    dataIndex: 'sender',
      //    key: 'sender',
      //    render: (sender) => (
      //        <Button type="link" onClick={() => openModal(sender)}>
      //           {sender.name}
      //        </Button>
      //    )
      // },
      {
         title: 'Склад Маҳсулот',
         dataIndex: 'warehouse',
         key: 'warehouse',
         render: (warehouse) => (
             <Button style={{color:'#00AA81'}} type="link" onClick={() => openModal(warehouse)}>
                {warehouse.name}
             </Button>
         )
      },
      {
         title: 'Тасдиқловчи',
         dataIndex: 'receive',
         key: 'receive',
         render: (receive) => (
             receive ? (
                 <Button style={{color:'#00AA81'}} type="link" onClick={() => openModal(receive)}>
                    {receive.name}
                 </Button>
             ) : (
                 <span
                     class="inline-flex gap-3 items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">     <svg
                     aria-hidden="true"
                     className="inline w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                     viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"/>
        <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill"/>
    </svg>Тасдиқланмаган</span>
             )
         )
      },
      {
         title: 'Холати',
         dataIndex: 'status',
         key: 'status',
         render: (status) => renderStatusTag(status)
      },
      {
         title: 'Изох',
         dataIndex: 'warehouse',
         key: 'comment',
         render: (warehouse, record) => {
            const history = warehouse.histories.find((hist) => hist.id === record.id);
            const comment = history?.history?.comment;
            return comment && comment.length > 10 ? (
                <Button style={{color: '#00AA81'}} type="link" onClick={() => openDescriptionModal(comment)}>
                   {comment.substring(0, 10)}...
                </Button>
            ) : (
                <span>{comment || 'Изох йўқ'}</span>
            );
         }
      },

      {
         title: 'Действия',
         key: 'actions',
         render: (text, record) => {
            if (record.status === 'cancel' || record.qty === 0 || record.status === 'process') {
               return null; // Do not render the button
            }
            return (
                <button
                    onClick={() => openReturnModal(record)}
                    className="group flex h-8 items-center text-white text-[13px] justify-center rounded-md border border-orange-600 bg-gradient-to-b from-orange-400 via-orange-500 to-orange-600 px-1.5 text-neutral-50 shadow-[inset_0_1px_0px_0px_#fdba74] active:[box-shadow:none]"
                    >
                <span className="flex items-center gap-2 group-active:[transform:translate3d(0,1px,0)]">
                    <MdAssignmentReturn size="20" />
                    Маҳсулотни қайтариш
                </span>
                    </button>
                );
             },
          },

       ]
   ;

   const handleTableChange = (pagination) => {
      getRequests(pagination.current);
   };

   return (
       <div className={'m-2'}>
          <div className="flex justify-between mb-4">
             <h1 className="text-xl font-semibold">Заявкалар</h1>
             <Button type="primary" onClick={openCreateModal}>
                Янгини яратиш
             </Button>
          </div>

          <Table
              columns={columns}
              dataSource={requests}
              rowKey="id"
              pagination={{
                 current: pagination.current,
                 pageSize: pagination.pageSize,
                 total: pagination.total,
              }}
              rowClassName={(record) => (record.qty === 0 ? 'bg-gray-50' : '')}
              loading={loading}
              onChange={handleTableChange}
          />

          <Modal
              title="Возврат товара"
              open={isReturnModalVisible}
              onCancel={closeReturnModal}
              onOk={() => returnForm.submit()}
              okText="Отправить"
          >
             <Form form={returnForm} onFinish={handleReturnRequest}>
                <Form.Item
                    label={`Миқдор (Энг кўп: ${selectedRequest?.qty})`}
                    name="qty"
                    rules={[
                       { required: true, message: 'Илтимос, миқдорни киритинг!' },
                       {
                          validator: (_, value) => {
                             if (!value || value <= selectedRequest?.qty) {
                                return Promise.resolve();
                             }
                             return Promise.reject(
                                 new Error(`Сизда борйоғи: ${selectedRequest?.qty}та бор`)
                             );
                          },
                       },
                    ]}
                >
                   <Input
                       type="number"
                       placeholder="Миқдорни киритинг"
                       max={selectedRequest?.qty}
                   />
                </Form.Item>


                <Form.Item label="Комментарий" name="comment">
                   <Input.TextArea placeholder="Введите комментарий (необязательно)" />
                </Form.Item>
             </Form>
          </Modal>

          {/* Modal for showing details */}
          <Modal
              title="Details"
              open={isModalVisible}
              onCancel={closeModal}
              footer={null}
          >
             {selectedRequest && (
                 <div>
                    <p><strong>Номи:</strong> {selectedRequest?.name}</p>
                    {
                        selectedRequest?.phone && (<p><strong>Рақами:</strong> {selectedRequest?.phone}</p>)
                    }
                    {selectedRequest?.category && (
                        <>
                           <p><strong>Категория:</strong> {selectedRequest?.category.name}</p>
                           {/*<p><strong>Миқдор:</strong> {selectedRequest?.qty}</p>*/}
                           {/*<p><strong>Нархи:</strong> {selectedRequest?.price}</p>*/}
                        </>
                    )}
                 </div>
             )}
          </Modal>

          {/* Modal for creating a new request */}
          <Modal
              centered
              title="Янги заявка яратиш"
              open={isCreateModalVisible}
              onCancel={closeCreateModal}
              onOk={() => form.submit()} // Submit the form when the OK button is clicked
              okText="Яратиш"
          >
             <Form form={form} onFinish={handleCreateRequest}>
                <Form.Item
                    label="Склад"
                    name="warehouse_id"
                    rules={[{ required: true, message: 'Илтимос, Маҳсулот танланг!' }]}
                >
                   <Select
                       allowClear
                       placeholder="Маҳсулот танланг"
                       showSearch
                       onChange={(value) => {
                          const selectedWarehouse = warehouses.find(warehouse => warehouse.id === value);
                          console.log(selectedWarehouse)
                          setSelectedWarehouse(selectedWarehouse); // Update the selected warehouse
                       }}
                       filterOption={(input, option) => {
                          const warehouseName = option.children[0].toLowerCase();
                          return warehouseName.includes(input.toLowerCase());
                       }}
                   >
                      {warehouses?.map(warehouse => (
                          <Select.Option
                              disabled={warehouse?.qty === 0}
                              key={warehouse?.id}
                              value={warehouse?.id}
                          >
                             {warehouse?.name} - <span
                              className="inline-flex gap-3 items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
            {warehouse.qty} та
          </span>
                          </Select.Option>
                      ))}
                   </Select>
                </Form.Item>

                <Form.Item
                    label="Миқдор"
                    name="qty"
                    rules={[
                       {
                          required: true,
                          message: 'Илтимос, миқдорни киритинг!'
                       },
                       {
                          validator: (_, value) => {
                             if (value === 0) {
                                return Promise.reject(new Error('Миқдор 0 бўлиши мумкин эмас!'));
                             }
                             if (value > selectedWarehouse.qty) {
                                return Promise.reject(new Error(`Енг кўп борйоғи: ${selectedWarehouse.qty}та`));
                             }

                             return Promise.resolve();
                          }
                       }
                    ]}
                >
                   <Input
                       type="number"
                       placeholder="Миқдорни киритинг"
                   />
                </Form.Item>

             </Form>
          </Modal>

          {/* Modal for full description */}
          <Modal
              title="Тўлиқ Изох"
              open={isDescriptionModalVisible}
              onCancel={closeDescriptionModal}
              footer={null}
          >
             <p>{fullDescription}</p>
          </Modal>
       </div>
   );
}

export default DoctorRequest;