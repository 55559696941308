import React, { useState, useEffect, useCallback } from "react";
import {Button, Popconfirm, Spin, Table, Tag, Tooltip,  message, Input} from "antd";
import {  QuestionCircleOutlined } from "@ant-design/icons";
import axiosInstance from "../../axios/axiosInstance";
import PatientFormModal from "./components/PatientFormModal";
import {FaAddressBook, FaUserEdit, FaUsersCog} from "react-icons/fa";
import {BiDialpadAlt} from "react-icons/bi";
import {Link} from "react-router-dom";
import {TrashIcon, UserGroupIcon} from "@heroicons/react/24/solid";
import {IoDocumentAttach, IoIdCard, IoReceiptSharp} from "react-icons/io5";
import {debounce} from "lodash";
import {LuPhoneForwarded} from "react-icons/lu";
import {CgNametag} from "react-icons/cg";
import {ImUserPlus} from "react-icons/im";
import {MdContentPasteSearch} from "react-icons/md";
import {HiDocumentMagnifyingGlass} from "react-icons/hi2";

const { Column } = Table;

const statusLabels = {
  queue: 'Навбатда...',
  examined: 'Қабулда...',
  new: 'Янги кабул',
  pending: 'Тўлов кутилмоқда',
  payed: 'Тўланган',
  revisit: 'Навбатда...',
  closed: 'Ёпилган',
  null: 'Фаол эмас',
};


const statusColors = {
  queue: '#EF9300',  // Muted yellow
  examined: '#4AC000',  // Flat green
  new: '#3C72F9',  // Flat sky blue
  pending: '#FFA866',  // Soft coral orange
  closed: '#ff3a3a',  // Flat red
  payed: '#9A77FF',  // Soft violet
  revisit: '#ffcf22',  // Muted yellow
  null: '#bdbdbd',  // Soft grey
};

const Patients = () => {
  const [patients, setPatients] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchCategory, setSearchCategory] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentPatient, setCurrentPatient] = useState(null);
  const [phoneSearchQuery, setPhoneSearchQuery] = useState("");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 15,
    total: 0
  });

  const fetchPatients = useCallback(
      async () => {
        setIsLoading(true);
        try {
          const token = localStorage.getItem('token');
          const params = {
            page: pagination.current,
            pageSize: pagination.pageSize,
          };

          if (searchQuery.trim()) {
            params.name = searchQuery;
          }


          const response = await axiosInstance.get(`/patients`, {
            params,
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json; charset=utf-8'
            }
          });

          setPatients(response.data.data);
          setPagination((prev) => ({
            ...prev,
            current: response.data.meta.current_page,
            total: response.data.meta.total
          }));
        } catch (error) {
          console.error("Ошибка при получении пациентов:", error);
        } finally {
          setIsLoading(false);
        }
      },
      [pagination.current, pagination.pageSize, searchQuery, phoneSearchQuery] // Watch pagination and search query
  );

  const debouncedPhoneSearch = useCallback(
      debounce((value) => {
        setPhoneSearchQuery(value); // Update phone search query
        fetchPatients(1); // Reset to first page on new search
      }, 500),
      []
  );

  const debouncedSearch = useCallback(
      debounce((value) => {
        setSearchQuery(value); // Update searchQuery
        setPagination((prev) => ({
          ...prev,
          current: 1,
        }));
      }, 500),
      []
  );

  const handleSearchChange = (e) => {
    debouncedSearch(e.target.value);
  };


  const handlePhoneSearchChange = (e) => {
    debouncedPhoneSearch(e.target.value);
  };

  useEffect(() => {
    fetchPatients();
  }, [fetchPatients]);


  const handleTableChange = (newPagination) => {
    setPagination((prev) => ({
      ...prev,
      current: newPagination.current,
    }));
  };

  const highlightText = (text, search) => {
    if (!search) return text;

    const regex = new RegExp(`(${search})`, 'gi');
    const parts = text.split(regex);

    return parts.map((part, index) =>
        regex.test(part) ? <span key={index} style={{ backgroundColor: '#ffc069', fontWeight: 'bold', color: '#fff' }}>{part}</span> : part
    );
  };

  const handleRemovePatient = async (patientId) => {
    try {
      const token = localStorage.getItem('token');
      await axiosInstance.delete(`/patients/${patientId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const updatedPatients = patients.filter(patient => patient.id !== patientId);
      setPatients(updatedPatients);
      message.success('Бемор учрилди!');
    } catch (error) {
      console.error("Ошибка при удалении пациента:", error);
    }
  };


  const handleOpenUpdateDialog = (patient) => {
    setCurrentPatient(patient);
    setIsModalVisible(true);
  };

  const handleClose = () => {
    setIsModalVisible(false);
    setCurrentPatient(null);
  };


  const handleSubmit = async (values) => {
    setIsLoading(true);
    const formData = new FormData();

    let phone = values.phone.replace(/\D/g, "");
    if (phone.length === 13) {
      phone = phone.slice(0, 12);
    }

    formData.append('name', values.name);
    formData.append('district_id', values.location.district_id);
    formData.append('partner_id', values.partner_id);
    if (values.pinfl) {
      formData.append('pinfl', values.pinfl);
    }
    formData.append('phone', phone);
    formData.append('profession', values.profession);
    formData.append('gender', values.gender);
    formData.append('home_address', values.home_address);
    formData.append('work_address', values.work_address);
    formData.append('remark', values.remark);
    if (values.file) {
      formData.append('file', values.file.file);
    }
    formData.append('birth_at', values.birth_at.format('YYYY-MM-DD'));

    try {
      if (currentPatient) {
        await axiosInstance.put(`/patients/${currentPatient.id}`, formData, {
          headers: {
            'Content-Type': 'application/json; charset=utf-8'
          }
        });
        fetchPatients(pagination.current);  // Re-fetch current page after updating
        message.success('Patient updated successfully!');
      } else {
        await axiosInstance.post('/patients', formData);
        fetchPatients(pagination.current);  // Re-fetch current page after creating
        message.success('Patient created successfully!');
      }
      handleClose();
    } catch (error) {
      message.error('Error saving patient');
      console.error('Error saving patient:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const formatPhone = (phone) => {
    if (!phone || phone.length !== 12) return phone; // Проверка длины номера
    return `+998 (${phone.slice(3, 5)}) ${phone.slice(5, 8)}-${phone.slice(8, 10)}-${phone.slice(10, 12)}`;
  };


  const columns = [
    {
      title: "№",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id.localeCompare(b.id),
    },
        {
          title: "ФИО",
          dataIndex: "name",
          key: "name",
          sorter: (a, b) => a.name.localeCompare(b.name),
          render: (text, record) => (
              <div className="flex items-center gap-3">
                <img
                    src={record.avatar}
                    alt="Avatar"
                    className="relative inline-block h-7 w-7 rounded-full border border-blue-gray-50 bg-blue-gray-50/50 object-contain object-center"
                />
                <div>
                  <div>
                    <Link to={`/patient/${record.id}`} className="block font-sans text-sm antialiased font-medium text-[#10b981]">
                      {highlightText(text, searchQuery)}
                    </Link>
                    <span className="block mt-0.5 font-sans text-xs antialiased text-gray-600">
                     Код: {record.code}
                    </span>
                  </div>
                </div>
              </div>
          )
        },

        {
          title: "Туғилган санаси",
          dataIndex: "birth_at",
          key: "birth_at",
          sorter: (a, b) => new Date(a.birth_at) - new Date(b.birth_at),
        },
        {
          title: "Холати",
          dataIndex: "visit_status",
          key: "visit_status",
          render: (visitStatus) => (
              <Tag
                  bordered={false}

                  className="px-3 py-1 h-8  flex gap-2 max-w-max items-center"
                  color={visitStatus ? statusColors[visitStatus.status] : 'default'}
              >
                <img
                    className="w-6 h-6"
                    src={visitStatus && visitStatus.status !== 'closed' ? '/check.svg' : '/noncheck.svg'}
                    alt={visitStatus && visitStatus.status !== 'closed' ? 'Not Closed' : 'Closed'}
                />
                {visitStatus ? statusLabels[visitStatus.status] : 'Статус неизвестен'}
              </Tag>
          ),
          // filters: Object.keys(statusLabels).map(key => ({ text: statusLabels[key], value: key })),
          // onFilter: (value, record) => record.visit_status.status === value,
          // filterMultiple: false, // Убираем кнопку "Очистить"
        },
        {
          title: "Манзил",
          dataIndex: "home_address",
          key: "home_address",
        },
        {
          title: "Телефон",
          dataIndex: "phone",
          key: "phone",
          render: (phone) => <span className="flex items-center gap-1">{formatPhone(phone)}</span>,
        },
        {
          title: "Харакат",
          key: "action",
          render: (text, record) => (
              <span className="flex items-center gap-1">
          <Tooltip color="orange" title="Бемор картаси">
   <Link to={`/patient/${record.id}`}
         className="group flex h-8 items-center justify-center rounded-md border border-orange-600 bg-gradient-to-b from-orange-400 via-orange-500 to-orange-600 px-1.5 text-neutral-50 shadow-[inset_0_1px_0px_0px_#fdba74] active:[box-shadow:none]"
   >
    <span className="block group-active:[transform:translate3d(0,1px,0)]">
      <HiDocumentMagnifyingGlass size="20" style={{color: '#fff'}}/>
    </span>
  </Link>
          </Tooltip>
                    <Tooltip color="#59b55d" title="Беморни ўзгартириш">
  <button onClick={() => handleOpenUpdateDialog(record)}
          className="group flex h-8 items-center justify-center rounded-md border border-green-600 bg-gradient-to-b from-green-400 via-green-500 to-green-600 px-1.5 text-neutral-50 shadow-[inset_0_1px_0px_0px_#34d399] active:[box-shadow:none]"
  >
    <span className="block group-active:[transform:translate3d(0,1px,0)]">
      <FaUserEdit size="20" style={{color: '#fff'}}/>
    </span>
  </button>
                    </Tooltip>

                   <button
                       className="group flex h-8 items-center justify-center rounded-md border border-teal-600 bg-gradient-to-b from-teal-400 via-teal-500 to-teal-600 px-1.5 text-neutral-50 shadow-[inset_0_1px_0px_0px_#4fd1c5] active:[box-shadow:none]"
                   >
    <span className="block group-active:[transform:translate3d(0,1px,0)]">
      <IoReceiptSharp size="20" style={{color: '#fff'}}/>
    </span>
  </button>
        </span>
          ),
        },
      ]
  ;

    return (
        <>
            <div className="px-10">
                {/*<Typography.Title level={3}>Барча беморлар</Typography.Title>*/}
                <div className="gap-5 flex items-center justify-between">
                    <div className="gap-5 flex items-center ">
                        {/*<div className="p-3 bg-gray-100 rounded-md">*/}
                        {/*  <UserGroupIcon color="#00AA81" className="w-7 h-7"/>*/}
              {/*</div>*/}
              <div>
                <h1 className="text-xl font-semibold mb-2">Барча беморлар</h1>
                <div className="w-full flex items-center justify-between" style={{marginBottom: 16}}>
                  <Input
                      prefix={<MdContentPasteSearch size="20"/>}
                      size="middle"
                      placeholder="Беморни Қидириш"
                      onChange={handleSearchChange}
                      className="ant-input rounded-md"
                      style={{width: 300}}
                  />
                </div>
              </div>
            </div>
            <Button icon={<FaUsersCog size="25"/>} className="text-sm flex items-center shadow-none rounded h-9 gap-1"
                    size="middle"
                    type="primary" onClick={() => setIsModalVisible(true)}>
              Беморни яратиш
            </Button>
          </div>
        </div>


        <Spin spinning={isLoading}>
          <Table
              rowSelection
              // scroll={{
              //   y: 121 * 5,
              // }}
              rowClassName="cursor-pointer even:bg-blue-gray-50/20"
              size="small"
              dataSource={patients}
              columns={columns}
              rowHoverable
              rowKey="id"
              pagination={{
                ...pagination,
                simple: true, // Минималистичный режим
                position: ["bottomCenter"], // Центрирование пагинации
                showTotal: (total, range) => (
                    <div>
      <span className="font-semibold text-[#00AA81]">
        {range[0]}–{range[1]}
      </span>{" "}
                      <span>Беморлар кўрсатилмоқда</span> |{" "}
                      <span className="font-semibold text-[#00AA81]">Жами: {total} та</span>
                    </div>
                ),
              }}
              onChange={handleTableChange}
          />
        </Spin>

        <PatientFormModal
            visible={isModalVisible}
            onClose={handleClose}
            onSubmit={handleSubmit}
            loading={isLoading}
            initialValues={currentPatient}
        />
      </>
  );
};

export default Patients;
